import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useState} from "react";
import {fr} from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

export type NoteType = 'observation' | 'call' | 'training' | 'flight-control';

export interface NewNoteDialogProps {
    open: boolean;
    noteType: NoteType;
    onChangeNoteType: (type: NoteType) => void;
    onNewCallNote: (date: Date, content: string) => void;
    onNewFlightControlNote: (date: Date) => void;
    onNewTrainingNote: (date: Date, description: string) => void;
    onNewObservationNote: (content: string) => void;
    onCancel: () => void;
}

export const NewNoteDialog = (props: NewNoteDialogProps) => {
    const {onCancel, onChangeNoteType, noteType, open, onNewCallNote, onNewTrainingNote, onNewObservationNote, onNewFlightControlNote} = props;

    const [trainingDescription, setTrainingDescription] = useState('');
    const [noteContent, setNoteContent] = useState('');
    const [relatedDate, setRelatedDate] = useState(new Date());

    const handleClose = () => {
        onCancel();
    };

    const handleNoteTypeChange = (evt) => {
        onChangeNoteType(evt.target.value);
    }

    const cancelButton = (): JSX.Element => {
        return <Button onClick={() => {
            handleClose();
        }} variant="outlined">Annuler</Button>
    }

    const datePicker = (label?: string): JSX.Element => {
        return <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                value={relatedDate}
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => {
                    setTimeout(() => setRelatedDate(date), 50);
                }}
            />
        </LocalizationProvider>
    }

    const newNoteContent = (): JSX.Element => {
        if (noteType === 'observation') {
            return <div>
                <Divider/>
                <div>
                    <TextField
                        id="observation-text"
                        label="Observation"
                        multiline
                        rows={8}
                        style={{width: '100%'}}
                        defaultValue=""
                        onChange={(txtInput) => {
                            setNoteContent(txtInput.target.value)
                        }}
                    />
                </div>
                <div className={'centered notes-buttons'}>
                    {cancelButton()}
                    <Button onClick={() => {
                        onNewObservationNote(noteContent);
                    }} variant="outlined">Ajouter</Button>
                </div>
            </div>
        }
        if (noteType === 'training') {
            return <div>
                <Divider/>
                <div>
                    <TextField
                        id="training-text"
                        label="Intitulé"
                        style={{width: '100%'}}
                        defaultValue=""
                        onChange={(txtInput) => {
                            setTrainingDescription(txtInput.target.value)
                        }}
                    />
                </div>
                <div style={{height: '129px'}}>
                    <div style={{marginTop: '10px'}} className={'centered'}>
                        {datePicker('Date de la formation')}
                    </div>
                </div>
                <div className={'centered notes-buttons'}>
                    {cancelButton()}
                    <Button onClick={() => {
                        onNewTrainingNote(relatedDate, trainingDescription);
                    }} variant="outlined">Ajouter</Button>
                </div>
            </div>
        }
        if(noteType === 'flight-control') {
            return <div>
                <Divider/>
                <div style={{height: '182px'}}>
                    <div style={{marginTop: '10px'}} className={'centered'}>
                        {datePicker("Date du contrôle")}
                    </div>
                </div>
                <div className={'centered notes-buttons'}>
                    {cancelButton()}
                    <Button onClick={() => {
                        onNewFlightControlNote(relatedDate);
                    }} variant="outlined">Ajouter</Button>
                </div>
            </div>
        }
        return <div>
            <Divider/>
            <div style={{height: '65px'}}>
                <div style={{marginTop: '10px'}} className={'centered'}>
                    {datePicker("Date de l'appel")}
                </div>
            </div>
            <div>
                <TextField
                    id="observation-text"
                    label="Details"
                    multiline
                    rows={4}
                    style={{width: '100%', marginTop: '5px'}}
                    defaultValue=""
                    onChange={(txtInput) => {
                        setNoteContent(txtInput.target.value)
                    }}
                />
            </div>
            <div className={'centered notes-buttons'}>
                {cancelButton()}
                <Button onClick={() => {
                    onNewCallNote(relatedDate, noteContent);
                }} variant="outlined">Ajouter</Button>
            </div>
        </div>
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Nouvelle note</DialogTitle>
            <div className={'new-note-pop-up'}>
                <FormControl fullWidth>
                    <InputLabel id="note-type">Type</InputLabel>
                    <Select
                        labelId="note-type"
                        id="note-type-select"
                        value={noteType}
                        label="Type"
                        onChange={handleNoteTypeChange}
                    >
                        <MenuItem value={'observation'}>Observation</MenuItem>
                        <MenuItem value={'call'}>Call</MenuItem>
                        <MenuItem value={'training'}>Formation</MenuItem>
                        <MenuItem value={'flight-control'}>Contrôle en vol</MenuItem>
                    </Select>
                </FormControl>
                <Divider/>
                <div>
                    {newNoteContent()}
                </div>
            </div>
        </Dialog>
    );
}
