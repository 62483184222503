
export const sum = (values: number[]): number => {
    let sumResult: number = 0;
    for (let index in values) {
        sumResult += values[index];
    }
    return sumResult;
}

export const formatDateWithDashes = (date: string) => {
    const dDate = new Date(date);

    const day: number = dDate.getDate();
    const strDay: string = day < 10 ? '0' + day.toString() : day.toString();
    const month: number = (dDate.getMonth().valueOf() + 1);
    const strMonth: string = month < 10 ? '0' + month.toString() : month.toString();
    const strYear: string = dDate.getFullYear().toString().slice(2, 4);

    return strDay + '-' + strMonth + '-' + strYear;
}

export const formatDateWithSlashes = (date: string) => {
    return formatDateWithDashes(date).replace('-', '/').replace('-', '/')
}
/*

export const nowPlusMilliseconds = (msToAdd: number): Date => {
    return new Date(Date.now() + msToAdd);
}

export const nowPlusSeconds = (sToAdd: number): Date => {
    //return new Date(Date.now() + sToAdd * (60 * 1000) )
    return nowPlusMilliseconds(sToAdd * 1000);
}

export const nowPlusMins = (minsToAdd: number): Date => {
    //return new Date(Date.now() + minsToAdd * (60 * 1000) )
    return nowPlusSeconds(60 * minsToAdd);
}

export const nowPlusHours = (hToAdd: number): Date => {
    //return new Date(Date.now() + hToAdd * (60 * 60 * 1000) )
    return nowPlusMins(60 * hToAdd);
}

export const nowPlusDays = (dToAdd: number): Date => {
    //return new Date(Date.now() + hToAdd * (60 * 60 * 1000) )
    return nowPlusHours(24 * dToAdd);
}

export const nowPlusYears = (yToAdd: number): Date => {
    //return new Date(Date.now() + hToAdd * (60 * 60 * 1000) )
    return nowPlusDays(365 * yToAdd);
}

*/
