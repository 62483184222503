import {Box, Card, CardHeader, Typography} from "@mui/material";
import {FlightEvaluation} from "../../../features/evaluations/model";

export interface FlightsCountBoxProps {
    flightEvaluations: FlightEvaluation[]
}

export const FlightsCountBox = ({flightEvaluations}: FlightsCountBoxProps) => {

    return (
        <Card>
            <Box p={2}>
                <div className={'centered'}>
                    <CardHeader title="Nombre Vols"/>
                </div>
                <div className={'centered'}>
                    <Typography variant="h3">{flightEvaluations.length}</Typography>
                </div>
            </Box>
        </Card>
        /*
        <Card style={{height: 200}}>
            <div className={'centered'}>
                <CardHeader title="Nombre Vols"/>
            </div>
            <Box pt={4}/>
            <div className={'centered'}>
                <Typography variant="h3">71</Typography>
            </div>
        </Card>
        */
    );
}
