import {AuthDetails, buildHeaders, get, post} from "./index";
import {PersonDto, PersonEvaluations} from "../../features/persons/model";
import {BACKEND_URL} from "../../configuration";
import {
    CallNoteDto,
    FlightControlNoteDto,
    Notes,
    ObservationNoteDto,
    TrainingNoteDto
} from "../../features/notes/model/notes.dto";

const API_ROOT_URL: string = "/dashboard/api";

export const getPersons = async (authDetails: AuthDetails): Promise<PersonDto[]> => {
    const path: string = API_ROOT_URL + '/persons';
    const httpResponse = await get(BACKEND_URL, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve persons");
    }

    return httpResponse.body;
}

export const getPersonEvaluations = async (authDetails: AuthDetails): Promise<PersonEvaluations[]> => {
    const path: string = API_ROOT_URL + '/evaluations';
    const httpResponse = await get(BACKEND_URL, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve persons");
    }

    return httpResponse.body;
}

export const getMe = async (authDetails: AuthDetails): Promise<PersonDto | undefined> => {
    const path: string = API_ROOT_URL + '/me';
    const httpResponse = await get(BACKEND_URL, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve my details");
    }

    return httpResponse.body;
}

export const getNotes = async (authDetails: AuthDetails): Promise<Notes> => {
    const path: string = API_ROOT_URL + '/notes';
    const httpResponse = await get(BACKEND_URL, path, buildHeaders(authDetails));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to retrieve notes");
    }

    return httpResponse.body;
}

export const addCallNote = async (authDetails: AuthDetails, callNote: CallNoteDto): Promise<CallNoteDto> => {
    const path: string = API_ROOT_URL + '/notes/call';
    const httpResponse = await post(BACKEND_URL, path, buildHeaders(authDetails), JSON.stringify(callNote));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to add call note");
    }

    return httpResponse.body;
}

export const addTrainingNote = async (authDetails: AuthDetails, trainingNote: TrainingNoteDto): Promise<TrainingNoteDto> => {
    const path: string = API_ROOT_URL + '/notes/training';
    const httpResponse = await post(BACKEND_URL, path, buildHeaders(authDetails), JSON.stringify(trainingNote));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to add training note");
    }

    return httpResponse.body;
}

export const addObservationNote = async (authDetails: AuthDetails, observationNote: ObservationNoteDto): Promise<ObservationNoteDto> => {
    const path: string = API_ROOT_URL + '/notes/observation';
    const httpResponse = await post(BACKEND_URL, path, buildHeaders(authDetails), JSON.stringify(observationNote));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to add observation note");
    }

    return httpResponse.body;
}

export const addFlightControlNote = async (authDetails: AuthDetails, flightControlNotes: FlightControlNoteDto): Promise<FlightControlNoteDto> => {
    const path: string = API_ROOT_URL + '/notes/flight-control';
    const httpResponse = await post(BACKEND_URL, path, buildHeaders(authDetails), JSON.stringify(flightControlNotes));

    if (httpResponse.statusCode && httpResponse.statusCode >= 400) {
        throw new Error("Unable to add flight control note");
    }

    return httpResponse.body;
}
