import './Note.css'
import {IconButton} from "@mui/material";
import {AddOutlined} from "@mui/icons-material";
import {useState} from "react";
import {NewNoteDialog, NoteType} from "./NewNoteDialog";
import {
    addCallNote,
    addFlightControlNote,
    addObservationNote,
    addTrainingNote
} from "../../../../api/client/dashboard.client";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {RootState} from "../../../../app/store";
import {refreshToken} from "../../../../api/auth/client";
import {AuthResponse} from "../../../../api/auth/model";
import {Notes} from "../../../../features/notes/model/notes.dto";
import {notesActions} from "../../../../features/notes/notesSlice";

export interface NewNoteProps {
    targetTrigram: string;
}

export const NewNote = (props: NewNoteProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [noteType, setNoteType] = useState<NoteType>('observation');
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state: RootState) => state.auth);
    const persons = useAppSelector((state: RootState) => state.persons);
    const notesState = useAppSelector((state: RootState) => state.notes);

    const existingNotes = (): Notes => {
        const existingNotes: Notes = {
            callNotes: [],
            observationNotes: [],
            trainingNotes: [],
            flightControlNotes: []
        }

        notesState.notes.callNotes.forEach(n => {
            existingNotes.callNotes.push(n)
        });

        notesState.notes.trainingNotes.forEach(n => {
            existingNotes.trainingNotes.push(n)
        });

        notesState.notes.observationNotes.forEach(n => {
            existingNotes.observationNotes.push(n)
        });

        notesState.notes.flightControlNotes.forEach(n => {
            existingNotes.flightControlNotes.push(n)
        });

        return existingNotes;
    }

    const handleClose = () => {
        setTimeout(() => setIsModalVisible(false), 10)
        setTimeout(() => setNoteType('observation'), 40)
    }

    const handleNoteTypeChange = (noteType: NoteType) => {
        setNoteType(noteType);
    }

    return <div className={'new-note centered'} onClick={() => setIsModalVisible(true)}>
        <IconButton
            color="inherit"
            size="small"
        >
            <AddOutlined fontSize="medium"/>
        </IconButton>
        <NewNoteDialog
            open={isModalVisible}
            noteType={noteType}
            onCancel={handleClose}
            onChangeNoteType={handleNoteTypeChange}
            onNewCallNote={(date, content) => {
                //console.debug('=> date: ', date)
                handleClose();
                refreshToken(auth.accessToken, auth.refreshToken)
                    .then((authResponse: AuthResponse) => {
                        addCallNote(
                            {accessToken: authResponse.accessToken},
                            {
                                date: date,
                                creator: persons.me.trigram,
                                target: props.targetTrigram,
                                content: content
                            })
                            .then((note) => {
                                const notes: Notes = existingNotes();
                                notes.callNotes.push(note);
                                dispatch(notesActions.onNotesChange(notes))
                            })
                            .catch()
                    })
                    .catch(e => console.error(e));
            }}
            onNewTrainingNote={(date, description) => {
                //console.debug('=> description: ', description)
                //console.debug('=> date: ', date)
                handleClose();
                refreshToken(auth.accessToken, auth.refreshToken)
                    .then((authResponse: AuthResponse) => {
                        addTrainingNote(
                            {accessToken: authResponse.accessToken},
                            {
                                date: date,
                                creator: persons.me.trigram,
                                target: props.targetTrigram,
                                description: description
                            })
                            .then((note) => {
                                const notes: Notes = existingNotes();
                                notes.trainingNotes.push(note);
                                dispatch(notesActions.onNotesChange(notes))
                            })
                            .catch()
                    })
                    .catch(e => console.error(e));
            }}
            onNewObservationNote={content => {
                //console.debug('=> content: ', content)
                handleClose();
                refreshToken(auth.accessToken, auth.refreshToken)
                    .then((authResponse: AuthResponse) => {
                        addObservationNote(
                            {accessToken: authResponse.accessToken},
                            {
                                creator: persons.me.trigram,
                                target: props.targetTrigram,
                                content: content,
                                date: new Date()
                            })
                            .then((note) => {
                                const notes: Notes = existingNotes();
                                notes.observationNotes.push(note);
                                dispatch(notesActions.onNotesChange(notes))
                            })
                            .catch()
                    })
                    .catch(e => console.error(e));
            }}
            onNewFlightControlNote={(date) => {
                //console.debug('=> date: ', date)
                handleClose();
                refreshToken(auth.accessToken, auth.refreshToken)
                    .then((authResponse: AuthResponse) => {
                        addFlightControlNote(
                            {accessToken: authResponse.accessToken},
                            {
                                date: date,
                                creator: persons.me.trigram,
                                target: props.targetTrigram
                            })
                            .then((note) => {
                                const notes: Notes = existingNotes();
                                notes.flightControlNotes.push(note);
                                dispatch(notesActions.onNotesChange(notes))
                            })
                            .catch()
                    })
                    .catch(e => console.error(e));
            }}
        />
    </div>
}
