import {Box, Card, CardHeader, Grid, Typography} from "@mui/material";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {computeRewards, RewardCount} from "../../../features/evaluations/evaluationsSlice";
import {useEffect, useState} from "react";

export interface TrophyCollectionBoxProps {
    flightEvaluations: FlightEvaluation[]
}

export const RewardsCollectionBox = ({flightEvaluations}: TrophyCollectionBoxProps) => {
    const [trophyCount, setTrophyCount] = useState<number>(0);
    const [medalCount, setMedalCount] = useState<number>(0);
    const [starCount, setStarCount] = useState<number>(0);

    useEffect(() => {
        const rewardsCounts: RewardCount[] = computeRewards(flightEvaluations);
        setTrophyCount(rewardsCounts.find(rc => rc.reward === 'trophy').count);
        setMedalCount(rewardsCounts.find(rc => rc.reward === 'medal').count);
        setStarCount(rewardsCounts.find(rc => rc.reward === 'star').count);
    }, [flightEvaluations]);

    return (
        <Card style={{height: 200}}>
            <div className={'centered'}>
                <CardHeader title="Collection"/>
            </div>
            <Box sx={{p: 2}}>
                <div className={'centered'}>
                    <Grid>
                        <Grid item container direction="row" xs={12}>
                            <Grid item xs={6}>
                                <div className={'centered'}>
                                    <img height={'32'} alt={""} src={'/static/images/rewards/reward-01-trophy.png'}/>
                                </div>
                            </Grid>
                            <Grid item style={{display: "flex", alignContent: 'center', alignItems: 'flex-end'}} xs={6}>
                                <Typography style={{
                                    paddingLeft: 10
                                }} variant={'h5'}>
                                    {trophyCount}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" xs={12}>
                            <Grid item xs={6}>
                                <div className={'centered'}>
                                    <img height={'32'} alt={""} src={'/static/images/rewards/reward-02-medal.png'}/>
                                </div>
                            </Grid>
                            <Grid item style={{display: "flex", alignContent: 'center', alignItems: 'flex-end'}} xs={6}>
                                <Typography style={{
                                    paddingLeft: 10
                                }} variant={'h5'}>
                                    {medalCount}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" xs={12}>
                            <Grid item xs={6}>
                                <div className={'centered'}>
                                    <img height={'32'} alt={""} src={'/static/images/rewards/reward-03-star.png'}/>
                                </div>
                            </Grid>
                            <Grid item style={{display: "flex", alignContent: 'center', alignItems: 'flex-end'}} xs={6}>
                                <Typography style={{
                                    paddingLeft: 10
                                }} variant={'h5'}>
                                    {starCount}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Card>
    );
}
