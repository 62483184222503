import {useParams} from "react-router";
import {Helmet} from "react-helmet-async";
import PageTitleWrapper from "../../../components/PageTitleWrapper";
import PageHeader from "../Overview/PageHeader";
import {Container, Grid} from "@mui/material";
import Footer from "../../../components/Footer";
import {IpncPncsGraph} from "./IpncPncsGraph";
import {useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import {PersonEvaluations} from "../../../features/persons/model";
import {IpncsGraph} from "./IpncsGraph";
import {GlobalRadar} from "./GlobalRadar";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {GlobalPercentageStatBox} from "./GlobalPercentageStatBox";
import {getFilteredEvaluations} from "../../../services/dashboard.service";
import {personsState} from "../../../features/persons/personsSlice";

function IPncStats() {
    const {trigram} = useParams();
    const evaluations = useAppSelector((state: RootState) => state.evaluations);
    const persons: personsState = useAppSelector((state: RootState) => state.persons);

    const content = (): JSX.Element => {
        const allEvaluations: FlightEvaluation[] = [];

        if ('all' === trigram) {

            getFilteredEvaluations(evaluations.personEvaluations, {
                fromDate: evaluations.fromDate,
                toDate: evaluations.toDate
            }).forEach(pe => {
                allEvaluations.push(pe)
            })

            return <Grid container>
                <Grid xs={12} md={12}>
                    <IpncsGraph evaluations={evaluations.personEvaluations}/>
                </Grid>
                <Grid style={{paddingTop: 30}} xs={6} md={6}>
                    <GlobalPercentageStatBox flightEvaluations={allEvaluations}/>
                </Grid>
                <Grid style={{paddingTop: 30}} xs={6} md={6}>
                    <GlobalRadar chartW={520} chartH={455} flightEvaluations={allEvaluations}/>
                </Grid>
            </Grid>
        }

        let ipncTrigram: string = trigram;

        if ('me' === trigram) {
            //@ts-ignore
            ipncTrigram = persons.me.trigram;
        }

        const ipncPncsEvaluations: PersonEvaluations[] =
            evaluations.personEvaluations.filter(
                pe => pe.person.referent !== undefined && pe.person.referent === ipncTrigram
            );

        getFilteredEvaluations(ipncPncsEvaluations, {
            fromDate: evaluations.fromDate,
            toDate: evaluations.toDate
        }).forEach(pe => {
            allEvaluations.push(pe)
        })

        return <Grid container>
            <Grid xs={12} md={12}>
                <IpncPncsGraph ipncTrigram={ipncTrigram} evaluations={ipncPncsEvaluations}/>
            </Grid>
            <Grid style={{paddingTop: 30}} xs={6} md={6}>
                <GlobalPercentageStatBox flightEvaluations={allEvaluations}/>
            </Grid>
            <Grid style={{paddingTop: 30}} xs={6} md={6}>
                <GlobalRadar chartW={520} chartH={455} flightEvaluations={allEvaluations}/>
            </Grid>
        </Grid>
    }
    /*
    return <div>
        <IpncPncsGraph ipncTrigram={trigram} evaluations={ipncPncsEvaluations}/>
    </div>
    */


    const subTitle = (): string => {
        if ('all' === trigram) {
            return "Synthèse de tous les IPNCs"
        }

        if ('me' === trigram) {
            return "Synthèse des PNCs"
        }

        return "Synthèse de " + trigram;
    }

    return (
        <>
            <Helmet>
                <title>CrewsBest Dashboard</title>
            </Helmet>
            <PageTitleWrapper>
                <PageHeader trigram={trigram} hideTitle={true} subTitle={subTitle()} displayPeriodPicker={true}/>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={4}
                >
                    <Grid item xs={12}>
                        {content()}
                    </Grid>

                </Grid>
            </Container>
            <Footer/>
        </>
    );
}

export default IPncStats;
