import {YearStatsTabsBox} from "../PncStats/YearStatsTabsBox";
import {FlightEvaluation} from "../../../features/evaluations/model";

export interface GlobalPercentageStatBoxProps {
    flightEvaluations: FlightEvaluation[]
}
export const GlobalPercentageStatBox = (props: GlobalPercentageStatBoxProps) => {
    return <div>
        <YearStatsTabsBox flightEvaluations={props.flightEvaluations} />
    </div>
}
