import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {fr} from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import {evaluationsActions, evaluationsState} from "../../features/evaluations/evaluationsSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";

export const PeriodPicker = () => {
    const dispatch = useAppDispatch();
    const evaluations: evaluationsState = useAppSelector((state: RootState) => state.evaluations);

    return <div>
        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
            <DatePicker
                label={"Du"}
                value={evaluations.fromDate}
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => {
                    console.debug('selected from date: ', date)
                    dispatch(evaluationsActions.onFromDateChange(date));
                }}
            />
        </LocalizationProvider>
        &nbsp;&nbsp;&nbsp;
        <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
            <DatePicker
                label={"Au"}
                value={evaluations.toDate}
                renderInput={(params) => <TextField {...params} />}
                onChange={(date) => {
                    console.debug('selected to date: ', date)
                    dispatch(evaluationsActions.onToDateChange(date));
                }}
            />
        </LocalizationProvider>
    </div>
}
