import {useContext} from 'react';

import {alpha, Box, Button, List, ListItem, ListSubheader, styled} from '@mui/material';
import {NavLink as RouterLink} from 'react-router-dom';
import {SidebarContext} from 'src/contexts/SidebarContext';
import {PersonDto, Role} from "../../../../features/persons/model";
import {Groups, QueryStatsOutlined, Remove} from "@mui/icons-material";
import {useAppSelector} from "../../../../app/hooks";
import {RootState} from "../../../../app/store";
import {personsState} from "../../../../features/persons/personsSlice";
import {isPurePnc} from "../../../../services/dashboard.service";

const MenuWrapper = styled(Box)(
    ({theme}) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
    ({theme}) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
        'transform',
        'opacity'
    ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
    const {closeSidebar} = useContext(SidebarContext);
    const personsState: personsState = useAppSelector((state: RootState) => state.persons);

    const isAdmin = (): boolean => {
        return personsState.me && personsState.me.roles.includes(Role.Admin);
    }

    const buildIpncMenu = (ipncTrigram: string, pncs: PersonDto[]) => {
        const ipnc: PersonDto = personsState.persons.find(p => p.trigram === ipncTrigram);
        let relativeUrl: string;
        return <List
            key={ipncTrigram}
            component="div"
            subheader={
                isAdmin()
                    ?
                    <ListSubheader component="div" disableSticky>
                        <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={"/dashboard/ipncs/" + ipnc.trigram}
                            startIcon={<Remove/>}
                        >
                            {ipnc.firstName}{' '}({ipnc.trigram})
                        </Button>
                    </ListSubheader>
                    :
                    <ListSubheader component="div" disableSticky>
                        {ipnc.firstName}{' '}({ipnc.trigram})
                    </ListSubheader>
            }
        >
            <SubMenuWrapper>
                <List component="div">
                    {
                        pncs.map((p, i) => {
                            relativeUrl = '/dashboard/' + p.trigram
                            return <ListItem key={i} component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to={relativeUrl}
                                    startIcon={<Remove/>}
                                >
                                    {p.firstName}{' '}({p.trigram})
                                </Button>
                            </ListItem>
                        })
                    }
                </List>
            </SubMenuWrapper>
        </List>
    }

    const buildIpncMenus = () => {

            const iPncPncsMap: Map<string, PersonDto[]> = new Map<string, PersonDto[]>();

            personsState.persons.forEach(p => {
                if (isPurePnc(p)) {
                    if (p.referent) {
                        if (!iPncPncsMap.get(p.referent)) {
                            iPncPncsMap.set(p.referent, [])
                        }
                        iPncPncsMap.get(p.referent).push(p)
                    }
                }
            });

            return <>

                {
                    Array.from(iPncPncsMap.entries()).map((entry) => {
                        const [key, value] = entry;
                        return buildIpncMenu(key, value);
                    })
                }

            </>
        }
    ;

    return <>
        <MenuWrapper>
            <List component="div">
                <SubMenuWrapper>
                    <List component="div">
                        <ListItem component="div">
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/dashboard/overview"
                                startIcon={<Groups/>}
                            >
                                Mes collaborateurs
                            </Button>
                        </ListItem>
                        {
                            isAdmin()
                                ?
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to="/dashboard/ipncs/all"
                                        startIcon={<QueryStatsOutlined/>}
                                    >
                                        Synthèse
                                    </Button>
                                </ListItem>
                                :
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to="/dashboard/ipncs/me"
                                        startIcon={<QueryStatsOutlined/>}
                                    >
                                        Synthèse
                                    </Button>
                                </ListItem>
                        }
                    </List>
                </SubMenuWrapper>
            </List>
            {buildIpncMenus()}
        </MenuWrapper>
    </>;
}

export default SidebarMenu;
