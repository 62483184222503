import {getMe} from "../api/client/dashboard.client";
import {PersonDto, PersonEvaluations, Role} from "../features/persons/model";
import {FlightEvaluation} from "../features/evaluations/model";

export const fetchMyDetails = (accessToken: string, onSuccess: (me: PersonDto | undefined) => void) => {
    getMe({accessToken: accessToken})
        .then(me => {
            onSuccess(me);
        })
        .catch(e => {
            console.warn('error: ', e)
        });
}


export const isPurePnc = (person: PersonDto): boolean => {
    if (person.roles.includes(Role.CCP)) {
        return false;
    }
    if (person.roles.includes(Role.CC)) {
        return false;
    }
    if (person.roles.includes(Role.Admin)) {
        return false;
    }
    if (person.roles.includes(Role.SysAdmin)) {
        return false;
    }
    if (person.roles.includes(Role.IPNC)) {
        return false;
    }

    return person.roles.includes(Role.PNC);
}

export interface evaluationsFilter {
    fromDate?: Date;
    toDate?: Date;
    trigram?: string;
    referent?: string;
}

export const getFilteredEvaluations = (personEvaluations: PersonEvaluations[], filter: evaluationsFilter): FlightEvaluation[] => {
    let currentPncFlightEvaluations: FlightEvaluation[] = [];

    if (filter.trigram) {
        personEvaluations.filter(pe => pe.trigram === filter.trigram).forEach(pe => {
            currentPncFlightEvaluations.push(...pe.evaluations);
        })
    } else {
        personEvaluations.forEach(pe => {
            currentPncFlightEvaluations.push(...pe.evaluations);
        })
    }


    if (filter.fromDate) {
        currentPncFlightEvaluations = currentPncFlightEvaluations
            .filter(e => new Date(e.date) >= filter.fromDate)
    }

    if (filter.toDate) {
        currentPncFlightEvaluations = currentPncFlightEvaluations
            .filter(e => new Date(e.date) <= filter.toDate)
    }

    return currentPncFlightEvaluations
        .sort(function (a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
    })
}
