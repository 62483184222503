import {useAppDispatch, useAppNavigate} from "../../app/hooks";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import {Box, Button, Card, Container} from "@mui/material";
import {Helmet} from "react-helmet-async";
import Logo from "../../components/Logo";
import TextField from "@mui/material/TextField";
import {KeyOutlined, MailOutlined} from '@mui/icons-material';
import {authenticateWithCode, sendValidationCodeTo} from "../../api/auth/client";
import {handleResponse} from "../../api/client";
import {authActions} from "../../features/authentication/authSlice";
import {personsActions} from "../../features/persons/personsSlice";
import {fetchMyDetails} from "../../services/dashboard.service";
import {PersonDto} from "../../features/persons/model";

export const CLEAN_EMAIL_INPUT_REGEX: string = '^[a-zA-Z0-9\\ \\_\\-\\.\\@]{1,}$';
export const CLEAN_CODE_INPUT_REGEX: string = '^[0-9\\ ]{1,7}$';

export const isInputClean = (input: string, regex: string): boolean => {
    return new RegExp(regex).test(input)
}

export const isCleanEmailInput = (input: string): boolean => {
    return isInputClean(input, CLEAN_EMAIL_INPUT_REGEX);
}

export const isCleanValidationCodeInput = (input: string): boolean => {
    return isInputClean(input, CLEAN_CODE_INPUT_REGEX);
}

export const isEmailValid = (inputEmail: string): boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputEmail);
}

const LoginWrapper = styled(Box)(
    () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

export const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const [userEmail, setUserEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [validationCode, setValidationCode] = useState<string>('');
    const [hasSentCode, setHasSentCode] = useState<boolean>(false);


    const sanitizeEmailInput = (detail: any) => {
        let newValue = detail.target.value;
        if (!isCleanEmailInput(newValue)) {
            detail.target.value = newValue.slice(0, -1);
        }

        if (!detail.target.value) {
            setUserEmail('');
            return;
        }

        if (isCleanEmailInput(detail.target.value!)) {
            setUserEmail(detail.target.value!);
        } else {
            //?
            setUserEmail('');
        }
    }

    const sanitizeCodeInput = (detail: any) => {
        let newValue = detail.target.value;
        if (!isCleanValidationCodeInput(newValue)) {
            detail.target.value = newValue.slice(0, -1);
        }

        if (!detail.target.value) {
            setValidationCode('');
            return;
        }

        if (isCleanValidationCodeInput(detail.target.value!)) {
            setValidationCode(detail.target.value!);
        } else {
            //?
            setValidationCode('');
        }
    }

    const startAuthentication = () => {
        setErrorMessage('');
        sendValidationCodeTo(userEmail)
            .then(r => {
                handleResponse(r,
                    () => {
                        setHasSentCode(true);
                    },
                    (errorMessage => {
                        setErrorMessage(errorMessage);
                    }));
            }).catch(e => {
            console.warn('error: ', e)
        });
    }

    const validateCode = () => {
        setErrorMessage('');

        authenticateWithCode(userEmail, validationCode)
            .then(rsp => {
                handleResponse(rsp,
                    (body) => {
                        console.debug('- auth response - ', body)
                        if (body.accessToken) {
                            dispatch(authActions.onAccessTokenChange(body.accessToken));
                            fetchMyDetails(body.accessToken, (me: PersonDto | undefined) => {
                                dispatch(personsActions.onMeChange(me));
                            });
                        }
                        if (body.refreshToken) {
                            dispatch(authActions.onRefreshTokenChange(body.refreshToken));
                        }
                        navigate("/dashboard");
                    },
                    (errorMessage => {
                        setErrorMessage(errorMessage);
                    }));
            }).catch(e => {
            console.warn('error: ', e)
        });

    }

    const enterEmailBlock = () => {
        let isButtonDisabled: boolean = true;
        if (isEmailValid(userEmail)) {
            isButtonDisabled = false;
        }

        return <>
            <div className={'centered'} style={{marginBottom: 15}}>
                <MailOutlined style={{opacity: 0.8, marginRight: 5}}/>
                <TextField
                    id="email"
                    style={{width: '80%'}}
                    placeholder="Votre email"
                    value={userEmail}
                    onChange={event => {
                        sanitizeEmailInput(event)
                    }}
                />
            </div>
            <div className={'centered'} style={{height: '10vh'}}>
                {errorMessage}
            </div>
            <div className={'centered'}>
                <Button
                    disabled={isButtonDisabled}
                    onClick={startAuthentication}
                    style={{width: 200}}
                    variant="outlined">
                    Valider
                </Button>
            </div>
        </>;
    }

    const validateCodeBlock = () => {
        let isButtonDisabled: boolean = true;
        if (validationCode.replace(' ', '').length === 6) {
            isButtonDisabled = false;
        }

        return <>
            <div className={'centered'} style={{marginBottom: 15}}>
                <KeyOutlined style={{opacity: 0.8, marginRight: 5}}/>
                <TextField
                    id="code"
                    style={{width: '80%'}}
                    placeholder="Entrez le code"
                    value={validationCode}
                    onChange={event => {
                        sanitizeCodeInput(event)
                    }}
                />
            </div>
            <div className={'centered'} style={{height: '10vh'}}>
                {errorMessage}
            </div>
            <div className={'centered'}>
                <Button
                    disabled={isButtonDisabled}
                    onClick={validateCode}
                    style={{width: 200}}
                    variant="outlined">
                    Valider
                </Button>
            </div>
        </>;
    }

    return (
        <LoginWrapper>
            <Helmet>
                <title>CrewsBest Dashboard</title>
            </Helmet>
            <Container maxWidth="lg">
                <Box display="flex" justifyContent="center" py={5} alignItems="center">
                    <Logo/>
                </Box>
                <Card sx={{borderRadius: 4, paddingTop: 5, height: '35vh'}}>
                    {hasSentCode ? validateCodeBlock() : enterEmailBlock()}
                </Card>
            </Container>
        </LoginWrapper>
    );
}
