import {ObservationNoteDto} from "../../../../features/notes/model/notes.dto";
import {Grid} from "@mui/material";

export interface ObservationNoteProps {
    details: ObservationNoteDto
}

export const ObservationNote = (props: ObservationNoteProps) => {
    return <div className={'post-it blue'}>
        <Grid container>
            <Grid item xs={12} md={12}>
                <div className={"centered"}> <h3>Observation</h3></div>
            </Grid>
            <Grid>
                {props.details.content}
            </Grid>
        </Grid>
    </div>
}
