import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PersonDto} from "./model";
import {getPreferences, setPreferences} from "../../services/storage.service";

const KEY: string = 'persons';

export const EMPTY_USER: PersonDto = {
    id: "0",
    trigram: "",
    firstName: "",
    lastName: "",
    roles: ['admin'],
    email: '',
    referent: undefined
};

export interface personsState {
    me: PersonDto | undefined
    persons: PersonDto[]
}

const initialState: personsState = {
    me: undefined,
    persons: []
}

export const loadStoredPreferences = createAsyncThunk(
    'persons/loadStoredPreferences',
    async () => {
        return await getPreferences<personsState>(KEY, initialState)
            .then((result) => {
                return result;
            });
    }
)

export const personsSlice = createSlice({
    name: 'persons',
    initialState,
    reducers: {
        onLoadPreferences: (state, action: PayloadAction<personsState>) => {
            const storedState: personsState = action.payload;
            state.me = storedState.me;
        },
        onPersonsChange: (state, action: PayloadAction<PersonDto[]>) => {
            state.persons = action.payload;
        },
        onMeChange: (state, action: PayloadAction<PersonDto | undefined>) => {
            state.me = action.payload;
            setPreferences(KEY, state).then().catch();
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        /*
        builder
            .addCase(loadStoredPreferences.fulfilled, () => {
            })
        */
    }
})

export const personsActions = personsSlice.actions

export default personsSlice.reducer
