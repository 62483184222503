import {useRoutes} from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import './theme/app.css';
import {useAppDispatch, useAppNavigate, useAppSelector} from "./app/hooks";
import {RootState} from "./app/store";
import {useEffect} from "react";
import {loadStoredPreferences as loadAuthPreferences} from "./features/authentication/authSlice";
import {loadStoredPreferences as loadPersonsPreferences, personsActions} from "./features/persons/personsSlice";
import {loadStoredPreferences as loadNotesPreferences} from "./features/notes/notesSlice";
import {fetchMyDetails} from "./services/dashboard.service";
import {PersonDto} from "./features/persons/model";

function App() {
    const content = useRoutes(router);
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector((state: RootState) => state.auth);

    useEffect(() => {
        console.debug('shouldAuthenticate ?: ', auth.shouldAuthenticate);
        if (auth.shouldAuthenticate) {
            navigate('/login');
        } else {
            fetchMyDetails(auth.accessToken, (me: PersonDto | undefined) => {
                dispatch(personsActions.onMeChange(me));
            });
            navigate('/dashboard')
        }
    }, [auth])

    useEffect(() => {
        dispatch(loadAuthPreferences());
        dispatch(loadPersonsPreferences());
        dispatch(loadNotesPreferences());
    }, [])

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline/>
                {content}
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
