import {FlightControlNoteDto} from "../../../../features/notes/model/notes.dto";
import {Grid} from "@mui/material";
import {formatDateWithSlashes} from "../../../../utils";

export interface FlightControlNoteProps {
    details: FlightControlNoteDto
}

export const FlightControlNote = (props: FlightControlNoteProps) => {
    return <div className={'post-it grey'}>
        <Grid container>
            <Grid item xs={12} md={12}>
                <div className={"centered"}>
                    <h3>Contrôle en vol</h3>
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
                <div className={"centered"}>
                    {formatDateWithSlashes(new Date(props.details.date).toISOString())}
                </div>
            </Grid>
        </Grid>
    </div>
}
