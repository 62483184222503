import {Card, CardContent, Container, Divider, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {YearPercentTabContent} from "./YearPercentTabContent";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {computePercentScore} from "../../../features/evaluations/evaluationsSlice";
import {CategoriesMultiGraphTabContent} from "./CategoriesMultiGraphTabContent";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export interface YearStatsTabsBoxProps {
    flightEvaluations: FlightEvaluation[];
    displayTitle?: boolean
}

export const YearStatsTabsBox = (props: YearStatsTabsBoxProps) => {
    const [value, setValue] = useState(0);
    const [anteFlightPercentValue, setAnteFlightPercentValue] = useState(0);
    const [preFlightPercentValue, setPreFlightPercentValue] = useState(0);
    const [welcomeFlightPercentValue, setWelcomeFlightPercentValue] = useState(0);
    const [serviceFlightPercentValue, setServiceFlightPercentValue] = useState(0);
    /*
        const handleChange = (event: SyntheticEvent, newValue: number) => {
            setValue(newValue);
        };

        <Grid item xs={6}>
            <Tabs
                variant={'fullWidth'}
                //                                    variant="fullWidth"
                scrollButtons="auto"
                //                                    textColor="secondary"
                //                                    indicatorColor="secondary"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
            >
                <Tab icon={<PercentIcon/>} {...a11yProps(0)} />
                <Tab icon={<TimeLineIcon/>}{...a11yProps(1)} />

            </Tabs>
        </Grid>
        */

    useEffect(() => {
        console.debug('==> YearStatsBox rerender')
        setAnteFlightPercentValue(computePercentScore('Briefing - AVANT VOL', props.flightEvaluations));
        setPreFlightPercentValue(computePercentScore("Préparation de l'appareil - PREVOL", props.flightEvaluations));
        setWelcomeFlightPercentValue(computePercentScore('Embarquement des passagers - ACCUEIL', props.flightEvaluations));
        setServiceFlightPercentValue(computePercentScore('En vol - SERVICE À BORD', props.flightEvaluations));
    }, [props.flightEvaluations]);

    return <div>
        <Container maxWidth="lg">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Card>
                        {props.displayTitle ?
                            <>
                                <Grid container direction="row">
                                    <Grid item xs={6}>
                                        <Typography style={{padding: 10}} variant={'h4'}>
                                            Cumul Période
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Divider/>
                            </>
                            : <div/>
                        }
                        <CardContent>
                            <Box sx={{width: '100%'}}>
                                <TabPanel value={value} index={0}>
                                    <YearPercentTabContent
                                        anteFlightPercentValue={anteFlightPercentValue}
                                        preFlightPercentValue={preFlightPercentValue}
                                        welcomeFlightPercentValue={welcomeFlightPercentValue}
                                        serviceFlightPercentValue={serviceFlightPercentValue}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <CategoriesMultiGraphTabContent
                                        flightEvaluations={props.flightEvaluations}
                                    />
                                </TabPanel>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </div>
}
