import {Middleware} from "@reduxjs/toolkit";
import {personsActions} from "../features/persons/personsSlice";

const personsMiddleware: Middleware = store => next => action => {
    if (action.type === 'persons/loadStoredPreferences/fulfilled') {
        store.dispatch(personsActions.onLoadPreferences(action.payload));
    }

    return next(action);
}
export default personsMiddleware;
