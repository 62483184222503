import {FlightEvaluation} from "../../../features/evaluations/model";
import {PncRadar} from "../PncStats/PncRadar";

export interface GlobalRadarProps {
    flightEvaluations: FlightEvaluation[]
    chartH?: number;
    chartW?: number;
}

export const GlobalRadar = (props: GlobalRadarProps) => {
    return <div>
        <PncRadar chartW={props.chartW} chartH={props.chartH} pnc={"all"} flightEvaluations={props.flightEvaluations}/>
    </div>
}
