import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import * as jose from "jose";
import {getPreferences, setPreferences} from "../../services/storage.service";

const KEY: string = 'auth';

export interface authState {
    shouldAuthenticate: boolean;
    accessToken: string;
    refreshToken: string;
    roles: string[];
    lastRefresh: Date;
}

const initialState: authState = {
    shouldAuthenticate: true,
    accessToken: '',
    refreshToken: '',
    roles: [],
    lastRefresh: new Date()
}


export const extractRolesFrom = (jwt: string): string[] => {
    const jwtPayload = jose.decodeJwt(jwt);
    if (jwtPayload && jwtPayload.roles) {
        // @ts-ignore
        return jwtPayload.roles;
    }
    return [];
}


export const loadStoredPreferences = createAsyncThunk(
    'auth/loadStoredPreferences',
    async () => {
        console.debug('==> on load preferences init: ')
        return await getPreferences<authState>(KEY, initialState)
            .then((result) => {
                return result;
            });
    }
)


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        onLoadPreferences: (state, action: PayloadAction<authState>) => {
            const storedState: authState = action.payload;
            state.roles = storedState.roles;
            state.shouldAuthenticate = storedState.shouldAuthenticate
            state.refreshToken = storedState.refreshToken
            state.accessToken = storedState.accessToken
            state.shouldAuthenticate = storedState.shouldAuthenticate
            state.lastRefresh = new Date()
        },
        onAccessTokenChange: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            state.shouldAuthenticate = false;
            state.roles = extractRolesFrom(action.payload);
            setPreferences(KEY, state).then().catch();
        },
        onRefreshTokenChange: (state, action: PayloadAction<string>) => {
            state.refreshToken = action.payload;
            setPreferences(KEY, state).then().catch();
        },
        onShouldAuthenticateChange: (state, action: PayloadAction<boolean>) => {
            state.shouldAuthenticate = action.payload;
            setPreferences(KEY, state).then().catch();
        },
        onLastRefreshChange: (state, action: PayloadAction<Date>) => {
            state.lastRefresh = action.payload;
            setPreferences(KEY, state).then().catch()
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        /*
        builder
            .addCase(loadStoredPreferences.fulfilled, () => {
            })
        */
    }
})

export const authActions = authSlice.actions

export default authSlice.reducer
