import {Box, Card, CardHeader, Typography} from "@mui/material";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {computeTotalScore} from "../../../features/evaluations/evaluationsSlice";

export interface TotalPointsBoxProps {
    flightEvaluations: FlightEvaluation[]
}

export const TotalPointsBox = ({flightEvaluations}: TotalPointsBoxProps) => {

// style={{height: 200}}
    return (
        <Card>
            <Box p={2}>
                <div className={'centered'}>
                    <CardHeader title="Cumul Points"/>
                </div>
                <div className={'centered'}>
                    <Typography variant="h3">{computeTotalScore(flightEvaluations)}</Typography>
                </div>
            </Box>
        </Card>
    );
}
