const APP_KEY: string = 'SmartEvalDashboard';

const computeAppKey = (key: string): string => {
    return APP_KEY + '.' + key;
}

export async function getPreferences<T>(key: string, defaultValue: T): Promise<T> {
    let preferences: T = defaultValue;
    const preferencesAsStrJson: string | undefined = localStorage.getItem(computeAppKey(key))

    if (preferencesAsStrJson) {
        preferences = JSON.parse(preferencesAsStrJson);

    }

    return preferences;
}

export async function setPreferences<T>(key: string, value: T) {
    localStorage.setItem(computeAppKey(key), JSON.stringify(value));
}

export const removePreferences = async (key: string) => {
    localStorage.removeItem(computeAppKey(key));
};
