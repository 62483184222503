import {CategoryEvaluation, FlightEvaluation} from "../../../features/evaluations/model";
import {Area, Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis,} from 'recharts';
import {useEffect, useState} from "react";
import {formatDateWithDashes} from "../../../utils";

export interface CategoriesMultiGraphTabContentProps {
    flightEvaluations: FlightEvaluation[]
}

interface ChartDataItem {
    date: string;
    welcome: number,
    service: number,
    preFlight: number,
    anteFlight: number,
}

export const CategoriesMultiGraphTabContent = ({flightEvaluations}: CategoriesMultiGraphTabContentProps) => {
    const [data, setData] = useState<ChartDataItem[]>([]);

    useEffect(() => {
        const chartData: ChartDataItem[] = [];
        const reversedOrdered: FlightEvaluation[] = flightEvaluations
            .sort(function (a, b) {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            })

        let preFlightEvaluation: CategoryEvaluation;
        let anteFlightEvaluation: CategoryEvaluation;
        let welcomeEvaluation: CategoryEvaluation;
        let serviceEvaluation: CategoryEvaluation;


        let workingMonth: string = '';
        let currentMonth: string = '';

        let preFlightValue: number = 0;
        let anteFlightValue: number = 0;
        let welcomeValue: number = 0;
        let serviceValue: number = 0;
        let chartDataItem: ChartDataItem;

        reversedOrdered.forEach(fe => {
            currentMonth = formatDateWithDashes(new Date(fe.date).toISOString());

            preFlightEvaluation = fe.categoryEvaluations.find(i => i.category === 'Pré-vol');
            anteFlightEvaluation = fe.categoryEvaluations.find(i => i.category === 'Avant-vol');
            welcomeEvaluation = fe.categoryEvaluations.find(i => i.category === 'Accueil');
            serviceEvaluation = fe.categoryEvaluations.find(i => i.category === 'Service');

            preFlightValue = preFlightEvaluation ? preFlightEvaluation.score : 0;
            anteFlightValue = anteFlightEvaluation ? anteFlightEvaluation.score : 0;
            welcomeValue = welcomeEvaluation ? welcomeEvaluation.score : 0;
            serviceValue = serviceEvaluation ? serviceEvaluation.score : 0;

            if (workingMonth !== currentMonth) {
                chartDataItem = {
                    date: currentMonth,
                    preFlight: preFlightValue,
                    anteFlight: anteFlightValue,
                    welcome: welcomeValue,
                    service: serviceValue
                }
                chartData.push(chartDataItem);
            } else {
                chartDataItem.preFlight += preFlightValue;
                chartDataItem.anteFlight += anteFlightValue;
                chartDataItem.welcome += welcomeValue;
                chartDataItem.service += serviceValue;
            }

            workingMonth = formatDateWithDashes(new Date(fe.date).toISOString());

        });

        setData(chartData);

    }, [flightEvaluations]);

    //console.debug('chartData: ', data)

    return (
        <div>
            <ComposedChart
                width={700}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 80,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid stroke="#f5f5f5"/>
                <XAxis dataKey="date" label={{value: 'Dates', position: 'insideBottomRight', offset: 0}}
                />
                <YAxis yAxisId="left" label={{value: 'Points', angle: -90, position: 'insideLeft'}}/>
                <YAxis yAxisId="right" orientation="right" label={{value: 'Points (Service)', angle: -90, position: 'insideLeft'}} />
                <Tooltip/>
                <Legend/>
                <Bar yAxisId={"right"} dataKey="service" name='Service' barSize={20} fill="#413ea0"/>
                <Area yAxisId={"left"} type="monotone" name='Pré-vol' dataKey="preFlight" fill="lightgreen" stroke="green"/>
                <Line yAxisId={"left"} type="monotone" name='Accueil' dataKey="welcome" stroke="#ff7300" strokeWidth={2} dot={false}/>
                <Line yAxisId={"left"} type="linear" name='Avant-Vol' dataKey="anteFlight" stroke="orange" strokeWidth={2} dot={false}/>
            </ComposedChart>
        </div>
    );
}
