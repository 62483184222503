import {CartesianGrid, Legend, Line, LineChart, ReferenceDot, ReferenceLine, Tooltip, XAxis, YAxis} from "recharts";
import {useEffect, useState} from "react";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {formatDateWithDashes} from "../../../utils";
import {Card} from "@mui/material";
import {CallNoteDto, FlightControlNoteDto, TrainingNoteDto} from "../../../features/notes/model/notes.dto";

/*
import { TooltipProps } from 'recharts';
// for recharts v2.1 and above
import {
    ValueType,
    NameType,
} from 'recharts/types/component/DefaultTooltipContent';
*/

interface PncScoreData {
    date: number;
    points: number;
}

export interface PncGraphProps {
    evaluations: FlightEvaluation[];
    callNotes: CallNoteDto[];
    trainingNotes: TrainingNoteDto[];
    flightControlNotes: FlightControlNoteDto[];
    chartW?: number;
    chartH?: number;
}

const toEpoch = (date: Date): number => {
    return date.getTime();
}

const dateFormatter = (date: Date) => {
    // return moment(date).unix();
    return formatDateWithDashes(new Date(date).toISOString());
};

export const PncGraph = (props: PncGraphProps) => {
    const chartW: number = props.chartW ? props.chartW : window.innerWidth > 600 ? window.innerWidth * 0.57 : window.innerWidth * 0.93
    const chartH: number = props.chartH ? props.chartH : window.innerHeight > 800 ? window.innerHeight * 0.5 : window.innerHeight * 0.3

    const [chartData, setChartData] = useState<PncScoreData[]>([]);
    const [chartKey, setChartKey] = useState<string>('');

    useEffect(() => {

        const data: PncScoreData[] = [];

        const sortedAllEvaluations: FlightEvaluation[] = props.evaluations
            .sort(function (a, b) {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            });

        sortedAllEvaluations.forEach(e => {
            data.push({
                date: toEpoch(new Date(e.date)),// formatDateWithDashes(new Date(e.date).toISOString()),
                points: e.totalScore
            })
        })

        setChartData(data);
        setChartKey(data.length.toString())

    }, [props.evaluations])

    const buildTrainingLines = () => {
        return <>
            {
                props.trainingNotes.map((tn, i) => {
                    return <ReferenceLine key={i} x={toEpoch(new Date(tn.date))} stroke="#c58c9d"
                                          label={tn.description}/>
                })
            }
        </>;
    }

    const computeCallDotYValue = (xDateValue: Date): number => {
        let xValue: number = toEpoch(xDateValue);

        const allValuesBefore: PncScoreData[] = chartData.filter(cd => cd.date <= xValue);
        const allValuesAfter: PncScoreData[] = chartData.filter(cd => cd.date >= xValue);

        let valueBefore: PncScoreData;
        let valueAfter: PncScoreData;


        if (allValuesBefore.length > 0) {
            valueBefore = allValuesBefore[allValuesBefore.length - 1];
        }

        if (allValuesAfter.length > 0) {
            valueAfter = allValuesAfter[0];
        }

        if (valueBefore && valueAfter) {
            let deltaDate = valueAfter.date - valueBefore.date;
            //let deltaFromLow = xValue - valueBefore.date;
            let deltaFromHigh = valueAfter.date - xValue;

            //let percentFromLow = deltaFromLow / deltaDate;
            let percentFromHigh = deltaFromHigh / deltaDate;

            let deltaValue = valueAfter.points - valueBefore.points;
            //let finalValueFromLow = valueBefore.points + percentFromLow * deltaValue;
            let finalValueFromHigh = valueAfter.points - (percentFromHigh) * deltaValue;

            //return (valueAfter.points + valueBefore.points) / 2 + 7;
            return finalValueFromHigh;// + 5;
        }

        return 0;
    }

    const buildCallDots = () => {
        /*
                    return <ReferenceDot key={"cn" + i.toString()} x={toEpoch(new Date(cn.date))}
                                         y={computeCallDotYValue(new Date(cn.date))}
                                         fill={"gold"}/>

        * */
        /*
                return <ReferenceLine key={"cn"+i.toString()}
                                      x={toEpoch(new Date(cn.date))}
                                      stroke="orange"
                        //label={'call'}
                                      strokeDasharray="3 3"/>
                */
        return <>
            {
                props.callNotes.map((cn, i) => {
                    return <ReferenceDot key={"cn" + i.toString()} x={toEpoch(new Date(cn.date))}
                                         y={computeCallDotYValue(new Date(cn.date))}
                                         fill={"gold"}/>

                })
            }
        </>;
    }

    const buildFlightControlLines = () => {
        return <>
            {
                props.flightControlNotes.map((tn, i) => {
                    return <ReferenceLine key={i} x={toEpoch(new Date(tn.date))} stroke="grey" strokeDasharray="3 3"
                                          label={""}/>
                })
            }
        </>;
    }

    const computeMinDomainValue = (): number => {
        if (chartData.length > 0) {
            return chartData[0].date;
        }
        return 0;
    }

    const computeMaxDomainValue = (): number => {
        if (chartData.length > 0) {
            return chartData[chartData.length - 1].date;
        }
        return 0;
    }

    //style={{cursor: "pointer"}}
    //                {buildFlightControlDots()}
    return <Card>
        <div className={'graph'}>

            <LineChart
                onClick={(d) => {
                    console.debug('on click chart d: ', d)
                }}
                key={chartKey}
                width={chartW} height={chartH}
                margin={{
                    top: 25,
                    right: 40,
                    bottom: 5,
                }}
                data={chartData}>
                <CartesianGrid strokeDasharray="1 11"/>
                <XAxis dataKey="date" textAnchor="middle"
                       scale="time"
                       type="number"
                       tickFormatter={dateFormatter}
                       domain={[computeMinDomainValue(), computeMaxDomainValue()]}
                />
                <YAxis domain={[0, 900]}/>
                <Tooltip labelFormatter={t => formatDateWithDashes(new Date(t).toISOString())}/>
                <Line name={"Score"} type="monotone" dataKey="points" stroke="#196F3D"/>
                <ReferenceLine y={880} label="" stroke="grey" strokeDasharray="3 3" />
                {buildFlightControlLines()}
                {buildTrainingLines()}
                {buildCallDots()}
                <Legend/>
            </LineChart>
        </div>
    </Card>
}
