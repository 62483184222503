import {PersonEvaluations} from "../../../features/persons/model";
import {useEffect, useState} from "react";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {CartesianGrid, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis} from "recharts";
import {formatDateWithDashes} from "../../../utils";
import {Card} from "@mui/material";
import {getFilteredEvaluations} from "../../../services/dashboard.service";
import {evaluationsState} from "../../../features/evaluations/evaluationsSlice";
import {useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";

interface IpncPncsGraphProps {
    ipncTrigram: string;
    evaluations: PersonEvaluations[]
}

export interface IpncMinMaxAverageData {
    ipncTrigram: string;
    date: string;
    minValue: number;
    maxValue: number;
    averageValue: number;
}

export const buildIpncGraphData = (flightEvaluations: FlightEvaluation[], trigram: string): IpncMinMaxAverageData[] => {
    const data: IpncMinMaxAverageData[] = [];

    const sortedAllEvaluations = flightEvaluations
        .sort(function (a, b) {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        })

    const allDates: string[] = sortedAllEvaluations.map(se => formatDateWithDashes(new Date(se.date).toISOString()));
    const uniqueDates: string[] = allDates.filter(function (item, pos) {
        return allDates.indexOf(item) == pos;
    })


    let currentDateFlightEvaluations: FlightEvaluation[] = [];

    let currentDatePncCount: number = 0;
    let currentDateTotalScore: number = 0;
    let currentDateAverageScore: number = 0;
    let currentDateHighScore: number = 0;
    let currentDateLowestScore: number = 10000000000;

    uniqueDates.forEach((d: string) => {
        currentDateFlightEvaluations = [];
        currentDatePncCount = 0;
        currentDateTotalScore = 0;
        currentDateHighScore = 0;
        currentDateAverageScore = 0;
        currentDateLowestScore = 10000000000;

        currentDateFlightEvaluations.push(...sortedAllEvaluations.filter(se => formatDateWithDashes(new Date(se.date).toISOString()) === d));

        currentDateFlightEvaluations.forEach(cfe => {
            currentDatePncCount++;
            if (cfe.totalScore > currentDateHighScore) {
                currentDateHighScore = cfe.totalScore
            }
            if (cfe.totalScore < currentDateLowestScore) {
                currentDateLowestScore = cfe.totalScore
            }
            currentDateTotalScore += cfe.totalScore
        })

        currentDateAverageScore = currentDateTotalScore / currentDatePncCount

        data.push({
            ipncTrigram: trigram,
            date: d,
            minValue: currentDateLowestScore,
            maxValue: currentDateHighScore,
            averageValue: currentDateAverageScore
        })

    });

    return data;
}

export const IpncPncsGraph = (props: IpncPncsGraphProps) => {
    const chartW: number = window.innerWidth > 600 ? window.innerWidth * 0.65 : window.innerWidth * 0.93
    const chartH: number = window.innerHeight > 800 ? window.innerHeight * 0.65 : window.innerHeight * 0.3

    const [chartData, setChartData] = useState<IpncMinMaxAverageData[]>([]);
    const [chartKey, setChartKey] = useState<string>('');

    const evaluations: evaluationsState = useAppSelector((state: RootState) => state.evaluations);

    useEffect(() => {

        const allFlightEvaluations: FlightEvaluation[] = [];

        getFilteredEvaluations(props.evaluations, {
            fromDate: evaluations.fromDate,
            toDate: evaluations.toDate
        }).forEach(pe => {
            allFlightEvaluations.push(pe)
        })

        const data: IpncMinMaxAverageData[] = buildIpncGraphData(allFlightEvaluations, props.ipncTrigram);

        setChartData(data);
        setChartKey(data.length.toString())

    }, [props.evaluations, evaluations])

    return <div>
        <Card>
            <div className={'graph'}>
                <LineChart
                    key={chartKey}
                    width={chartW} height={chartH}
                    margin={{
                        top: 45,
                        right: 40,
                        bottom: 5,
                    }}
                    data={chartData}>
                    <CartesianGrid strokeDasharray="1 11"/>
                    <XAxis dataKey="date" textAnchor="middle"/>
                    <ReferenceLine y={880} label="" stroke="grey" strokeDasharray="3 3" />
                    <YAxis domain={[0, 900]}/>
                    <Tooltip/>
                    <Line name={"Minimum"} type="monotone" dataKey="minValue" stroke="#78112C"/>
                    <Line name={"Maximum"} type="monotone" dataKey="maxValue" stroke="#172699"/>
                    <Line name={"Moyenne"} type="monotone" dataKey="averageValue" stroke="#196F3D" strokeDasharray="3 4 5 2"/>
                    <Legend/>
                </LineChart>
            </div>
        </Card>
    </div>
}
