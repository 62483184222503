import {HttpCustomError, HttpCustomResponse} from "../model";

export const BACKEND_NOT_REACHABLE_MESSAGE: string = 'Backend not reachable';
export const UNEXPECTED_ERROR_OCCURRED = 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.'

export const buildRequestInit = (method: 'GET' | 'POST', headers: HeadersInit, body?: BodyInit): RequestInit => {
    if (body) {
        return {
            method: method,
            mode: "cors",
            headers: headers,
            body: body
        }

    }
    return {
        method: method,
        mode: "cors",
        headers: headers
    }
}

export interface AuthDetails {
    accessToken: string;
}

export const buildHeaders = (authDetails?: AuthDetails): HeadersInit => {
    if (!authDetails) {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authDetails.accessToken
    }
}

export const callBackend = async (url: string, apiPath: string, requestInit: RequestInit): Promise<any> => {
    console.debug(' --- ---> calling backend on path : ' + url + apiPath + ' with init: ', requestInit);
    try {
        return await (async () => {
            const rawResponse = await fetch(url + apiPath, requestInit);
            const content = await rawResponse.json();
            console.debug(' <--- --- response received: ', content)
            if (content.statusCode && typeof content.statusCode === 'number') {
                return content;
            }
            const response: HttpCustomResponse = {
                statusCode: 200,
                body: content
            }
            return response;
        })();
    } catch (ex) {
        console.warn('fetch exception: ', ex);
        return await (async () => {
            const error: HttpCustomError = {
                statusCode: 502, message: BACKEND_NOT_REACHABLE_MESSAGE
            }
            return error;
        })();
    }
}

export const post = async (url: string, apiPath: string, headers: HeadersInit, body?: BodyInit): Promise<any> => {
    const requestInit: RequestInit = buildRequestInit(
        'POST',
        headers,
        body);

    return callBackend(url, apiPath, requestInit);
}

export const get = async (url: string, apiPath: string, headers: HeadersInit): Promise<any> => {
    const requestInit: RequestInit = buildRequestInit(
        'GET',
        headers
    );

    return callBackend(url, apiPath, requestInit);
}

export const handleResponse = (response: any, onSuccess: (body: any) => void, onError: (reasonMessage: string) => void) => {
    console.debug(' --- handleResponse --- ', response)
    if (response.statusCode && response.statusCode === 200) {
        onSuccess(response.body);
    }

    if (response.statusCode && response.statusCode !== 200) {
        if (response.message) {
            onError(response.message);
        } else {
            onError(UNEXPECTED_ERROR_OCCURRED);
        }
    }
}
