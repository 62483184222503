import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getPreferences, setPreferences} from "../../services/storage.service";
import {Notes} from "./model/notes.dto";

const KEY: string = 'notes';

export interface notesState {
    notes: Notes
}

const initialState: notesState = {
    notes: {
        trainingNotes: [],
        callNotes: [],
        observationNotes: [],
        flightControlNotes: []
    }
}

export const loadStoredPreferences = createAsyncThunk(
    'notes/loadStoredPreferences',
    async () => {
        return await getPreferences<notesState>(KEY, initialState)
            .then((result) => {
                return result;
            });
    }
)

export const notesSlice = createSlice({
    name: 'notes',
    initialState,
    reducers: {
        onLoadPreferences: (state, action: PayloadAction<notesState>) => {
            const storedState: notesState = action.payload;
            state.notes = storedState.notes;
        },
        onNotesChange: (state, action: PayloadAction<Notes>) => {
            console.debug('*** onNotesChange: ', action.payload)
            state.notes = action.payload;
            setPreferences(KEY, state).then().catch();
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(loadStoredPreferences.fulfilled, () => {
            })
    }
})

export const notesActions = notesSlice.actions

export default notesSlice.reducer
