import {PersonEvaluations, PncStatus, RankedPnc} from "../persons/model";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CategoryEvaluation, FlightEvaluation} from "./model";
import {sum} from "../../utils";

interface TrigramScore {
    trigram: string
    score: number
}

export const sortTrigramScores = (trigramScores: TrigramScore[], desc?: boolean): TrigramScore[] => {
    if (desc) {
        return trigramScores
            .slice()
            .sort(
                (a, b) => a.score - b.score
            )
    }
    return trigramScores
        .slice()
        .sort(
            (a, b) => b.score - a.score
        )
}

const computePncStatus = (flightEvaluations: FlightEvaluation[]): PncStatus => {
    const statuses: PncStatus[] = ["sun", "cloud", "rain"]
    const randomElement = statuses[Math.floor(Math.random() * statuses.length)];
    return randomElement;
}


const computeRankedPncs = (evaluations: PersonEvaluations[]): RankedPnc[] => {
    const rankedPnc: RankedPnc[] = [];
    const trigramScores: TrigramScore[] = [];

    let currentPersonScore: TrigramScore | undefined = undefined;

    evaluations.forEach(personEvaluation => {
        currentPersonScore = trigramScores.find(ts => ts.trigram === personEvaluation.trigram)
        if (!currentPersonScore) {
            trigramScores.push({
                trigram: personEvaluation.trigram,
                score: sum(personEvaluation.evaluations.map(e => e.totalScore))
            })
        }
    });

    const sortedScores: TrigramScore[] = sortTrigramScores(trigramScores);

    let evaluation: PersonEvaluations | undefined = undefined;

    for (let i = 0; i < sortedScores.length; i++) {
        evaluation = evaluations.find(e => e.trigram === sortedScores[i].trigram);
        if (evaluation) {
            rankedPnc.push({
                pnc: evaluation.person,
                ranking: i + 1,
                total: evaluations.length,
                status: computePncStatus(evaluation.evaluations)
            })

        }
    }

    return rankedPnc;
}

export interface evaluationsState {
    personEvaluations: PersonEvaluations[],
    fromDate: Date,
    toDate: Date,
    rankedPnc: RankedPnc[]
}

const initialState: evaluationsState = {
    personEvaluations: [],
    fromDate: new Date(new Date().getFullYear(), 0, 1),
    toDate: new Date(),
    rankedPnc: []
}

export const evaluationsSlice = createSlice({
    name: 'evaluations',
    initialState,
    reducers: {
        onPersonEvaluationsChange: (state, action: PayloadAction<PersonEvaluations[]>) => {
            console.debug('*** onPersonEvaluationsChange: ', action.payload)
            state.personEvaluations = action.payload;
            state.rankedPnc = computeRankedPncs(action.payload)
        },
        onFromDateChange: (state, action: PayloadAction<Date>) => {
            state.fromDate = action.payload
        },
        onToDateChange: (state, action: PayloadAction<Date>) => {
            state.toDate = action.payload
        }
    }
    /*,
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(loadStoredPreferences.fulfilled, () => {
            })
    }
    */
})

export const evaluationsActions = evaluationsSlice.actions

export default evaluationsSlice.reducer

//

export interface RewardCount {
    reward: 'trophy' | 'medal' | 'star';
    count: number;
}

const TROPHY_DELTA: number = 200;
const MEDAL_DELTA: number = 100;
const STAR_DELTA: number = 50;

export const computeRewards = (flightEvaluations: FlightEvaluation[]): RewardCount[] => {
    const reversedOrdered: FlightEvaluation[] = flightEvaluations
        .sort(function (a, b) {
            return new Date(a.date).getTime() - new Date(b.date).getTime();
        })

    const result: RewardCount[] = [];

    let trophyCount: number = 0;
    let medalCount: number = 0;
    let starCount: number = 0;

    let currentScore: number = 0;
    let previousScore: number = 0;

    for (let i = 1; i < reversedOrdered.length; i++) {
        currentScore = computeScore(reversedOrdered[i]);
        previousScore = computeScore(reversedOrdered[i - 1]);

        if (currentScore - previousScore >= TROPHY_DELTA) {
            trophyCount += 1;
        } else if (currentScore - previousScore >= MEDAL_DELTA) {
            medalCount += 1;
        } else if (currentScore - previousScore >= STAR_DELTA) {
            starCount += 1;
        }
    }

    result.push(
        {
            reward: "trophy",
            count: trophyCount
        },
        {
            reward: "medal",
            count: medalCount
        },
        {
            reward: "star",
            count: starCount
        }
    )

    return result;
}

export const computePercentScore = (category: string, flightEvaluations: FlightEvaluation[]): number => {
    const categoryEvaluations: CategoryEvaluation[] = [];

    flightEvaluations.forEach(fe => {
        categoryEvaluations.push(...fe.categoryEvaluations.filter(ce => ce.category === category));
    })

    let categoryTotalScore: number = 0;
    let categoryMaximumScore: number = 0;

    categoryEvaluations.forEach(ce => {
        categoryTotalScore += ce.score;
        categoryMaximumScore += ce.theoreticalMaximum;
    });

    return Math.floor(categoryTotalScore * 100 / categoryMaximumScore);
}

export const computeScore = (flightEvaluation: FlightEvaluation): number => {
    let score: number = 0;
    flightEvaluation.categoryEvaluations.forEach(ce => {
        score += ce.score;
    });
    return score;
}

export const computeTotalScore = (flightEvaluations: FlightEvaluation[]): number => {
    let score: number = 0;
    flightEvaluations && flightEvaluations.forEach(fe => {
        score += computeScore(fe);
    })
    return score;
}
