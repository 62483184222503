import {FlightEvaluation} from "../../evaluations/model";

export interface PersonDto {
    id: string;
    trigram: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    referent: string | undefined;
}

export type PncStatus = 'sun' | 'cloud' |'rain';

export interface RankedPnc {
    pnc: PersonDto;
    ranking: number;
    total: number;
    status: PncStatus;
}

export interface PersonEvaluations {
    trigram: string;
    person: PersonDto;
    evaluations: FlightEvaluation[]
}

export enum Role {
    //User = 'user',
    Admin = 'admin',
    SysAdmin = 'system-admin',
    PNC = 'pnc',
    CC = 'cc',
    CCP = 'ccp',
    IPNC = 'ipnc',
}
