import {configureStore} from "@reduxjs/toolkit";
import authReducer from "../features/authentication/authSlice";
import evaluationsReducer from "../features/evaluations/evaluationsSlice";
import personsReducer from "../features/persons/personsSlice";
import notesReducer from "../features/notes/notesSlice";
import authMiddleware from "../middleware/authenticationMiddleware";
import personsMiddleware from "../middleware/personsMiddleware";
import notesMiddleware from "../middleware/notesMiddleware";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        evaluations: evaluationsReducer,
        persons: personsReducer,
        notes: notesReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
        .concat(
            authMiddleware,
            personsMiddleware,
            notesMiddleware
        )
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
