import {useEffect, useRef, useState} from 'react';

import {NavLink} from 'react-router-dom';

import {
    Avatar,
    Box,
    Button,
    Divider,
    Hidden,
    lighten,
    List,
    ListItem,
    ListItemText,
    Popover,
    Typography
} from '@mui/material';
import {styled} from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {authActions} from "../../../../features/authentication/authSlice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";

import {RootState} from "../../../../app/store";
import {EMPTY_USER, personsState} from "../../../../features/persons/personsSlice";
import {PersonDto} from "../../../../features/persons/model";

const UserBoxButton = styled(Button)(
    ({theme}) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({theme}) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({theme}) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const persons: personsState = useAppSelector((state: RootState) => state.persons);
    const [me, setMe] = useState<PersonDto>(EMPTY_USER);

    useEffect(() => {
        if (persons.me) {
            setMe(persons.me);
        }
    }, [persons])

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                <Avatar variant="rounded" alt={''}>
                    {me.trigram}
                </Avatar>
                <Hidden mdDown>
                    <UserBoxText>
                        <UserBoxLabel
                            variant="body1">{me.firstName}{' '}{me.lastName}</UserBoxLabel>
                        <UserBoxDescription variant="body2">
                            {me.trigram}
                        </UserBoxDescription>
                    </UserBoxText>
                </Hidden>
                <Hidden smDown>
                    <ExpandMoreTwoToneIcon sx={{ml: 1}}/>
                </Hidden>
            </UserBoxButton>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <MenuUserBox sx={{minWidth: 210}} display="flex">
                    <Avatar variant="rounded" alt={''}>
                        {me.trigram}
                    </Avatar>

                    <UserBoxText>
                        <UserBoxLabel
                            variant="body1">{me.firstName}{' '}{me.lastName}</UserBoxLabel>
                        <UserBoxDescription variant="body2">
                            {me.trigram}
                        </UserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                <Divider sx={{mb: 0}}/>
                <List sx={{p: 1}} component="nav">
                    <ListItem button to="/management/profile/details" component={NavLink}>
                        <AccountBoxTwoToneIcon fontSize="small"/>
                        <ListItemText primary="Mon Profil"/>
                    </ListItem>
                </List>
                <Divider/>
                <Box sx={{m: 1}}>
                    <Button color="primary"
                            fullWidth
                            onClick={() => {
                                dispatch(authActions.onShouldAuthenticateChange(true));
                            }}
                    >
                        <LockOpenTwoToneIcon sx={{mr: 1}}/>
                        Sign out
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

export default HeaderUserbox;
