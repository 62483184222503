import {AuthResponse, PasswordLessRequest, RefreshTokenRequest} from "../model"
import {buildHeaders, post} from "../../client";
import * as jose from "jose";
import {JWTPayload} from "jose";
import { BACKEND_URL } from "../../../configuration";

export const sendValidationCodeTo = async (email: string): Promise<any> => {
    const requestBody: PasswordLessRequest = {
        email: email
    }
    const apiPath: string = '/auth/init'
    return await post(BACKEND_URL, apiPath, buildHeaders(), JSON.stringify(requestBody))
}

export const authenticateWithCode = async (email: string, validationCode: string): Promise<any> => {
    const requestBody: PasswordLessRequest = {
        email: email,
        code: validationCode
    }
    const apiPath: string = '/auth/validate'
    return await post(BACKEND_URL, apiPath, buildHeaders(), JSON.stringify(requestBody))
}


export const refreshToken = async (accessToken: string, refreshToken: string): Promise<AuthResponse> => {
    const jwtPayload: JWTPayload = jose.decodeJwt(accessToken);

    //jwt token has expired
    if (jwtPayload.exp && jwtPayload.exp && Date.now() >= jwtPayload.exp * 1000) {
        const requestBody: RefreshTokenRequest = {
            email: jwtPayload.email as string,
            refreshToken: refreshToken
        }

        const apiPath: string = '/auth/refresh'
        const response = await post(BACKEND_URL, apiPath, buildHeaders(), JSON.stringify(requestBody));

        //some issue with the refresh token (it has expired or something else)
        if (response.statusCode && response.statusCode >= 400) {
            throw new Error('Could not refresh token');
        }
        //successfully refresh the accessToken, can use it to perform the required action
        return {
            accessToken: response.body.accessToken,
            refreshToken: response.body.refreshToken
        };
    }
    //jwt token is still valid
    return {
        accessToken: accessToken,
        refreshToken: refreshToken
    }
}
