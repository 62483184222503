import {Avatar, Grid, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {EMPTY_USER, personsState} from "../../../features/persons/personsSlice";
import {useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import {useEffect, useState} from "react";
import {PersonDto} from "../../../features/persons/model";
import {PeriodPicker} from "../../../components/PeriodPicker/PeriodPicker";

interface PageHeaderProps {
    subTitle?: string;
    hideTitle?: boolean;
    trigram?: string;
    displayPeriodPicker?: boolean;
}

function PageHeader(props: PageHeaderProps) {

    const theme = useTheme();
    const persons: personsState = useAppSelector((state: RootState) => state.persons);
    const [me, setMe] = useState<PersonDto>(EMPTY_USER);

    useEffect(() => {
        if (persons.me) {
            setMe(persons.me);
        }
    }, [persons])

    const isIpnc = (): boolean => {
        return 'all' !== props.trigram;
    }

    const avatar = () => {
        return isIpnc() ?
            <Avatar
                sx={{
                    mr: 2,
                    width: theme.spacing(8),
                    height: theme.spacing(8)
                }}
                variant="rounded"
            >
                {props.trigram}
            </Avatar>
            :
            <Avatar
                sx={{
                    mr: 2,
                    width: theme.spacing(8),
                    height: theme.spacing(8)
                }}
                variant="rounded"
            >
            </Avatar>
    }

    return (
        <Grid container alignItems="center">
            <Grid item md={1}>
                {avatar()}
            </Grid>
            <Grid item md={6}>
                {
                    props.hideTitle ?
                        <div/> :
                        <Typography variant="h3" component="h3" gutterBottom>
                            Bonjour, {me.firstName}!
                        </Typography>
                }

                <Typography variant="subtitle2">
                    {props.subTitle ? props.subTitle : ''}
                </Typography>
            </Grid>
            <Grid item md={5}>
                {props.displayPeriodPicker ? <PeriodPicker/> : <div/>}
            </Grid>
        </Grid>
    );
}

export default PageHeader;
