import {CallNoteDto} from "../../../../features/notes/model/notes.dto";
import './Note.css';
import {Grid} from "@mui/material";
import {formatDateWithSlashes} from "../../../../utils";

export interface CallNoteProps {
    details: CallNoteDto
}

export const CallNote = (props: CallNoteProps) => {
    return <div className={'post-it yellow'}>
        <Grid container>
            <Grid item xs={12} md={12}>
                <div className={"centered"}>
                    <h3>Call</h3>
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
                <div className={"centered"}>
                    {formatDateWithSlashes(new Date(props.details.date).toISOString())}
                </div>
            </Grid>
            <Grid>
                {props.details.content}
            </Grid>
        </Grid>
    </div>
}
