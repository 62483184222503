import {useTheme} from "@mui/material/styles";
import {Avatar, Grid, Typography} from "@mui/material";
import {PeriodPicker} from "src/components/PeriodPicker/PeriodPicker";

function PageHeader({trigram}) {
    const theme = useTheme();

    return (
        <Grid container alignItems="center">
            <Grid item md={1}>
                <Avatar
                    sx={{
                        mr: 2,
                        width: theme.spacing(8),
                        height: theme.spacing(8)
                    }}
                    variant="rounded"
                >
                    {trigram}
                </Avatar>
            </Grid>
            <Grid item md={6}>
                <Typography variant="subtitle2">
                    Statistiques de {trigram}
                </Typography>
            </Grid>
            <Grid item md={5}>
                <PeriodPicker/>
            </Grid>
        </Grid>
    );
}

export default PageHeader;
