import {Box, Grid, LinearProgress, Typography} from "@mui/material";


/*
<Grid xs={12}>
                    <Grid item xs={6}>
                        Pre vol
                    </Grid>
                    <Grid item xs={6}>
                        Avant vol
                    </Grid>
                </Grid>
                <Grid xs={12}>
                    <Grid item xs={6}>
                        Accueil
                    </Grid>
                    <Grid item xs={6}>
                        Service
                    </Grid>
                </Grid>
* * */

const PercentDimension = ({label, value, color}) => {
    return <>
        <Box sx={{p: 1}}>
            <Box sx={{p: 2}}>
                <Grid
                    container
                    direction="row">
                    <Grid item xs={12} md={4}>
                        <Typography color={'darkgray'} variant={'h3'}>{value} %</Typography>
                    </Grid>
                    <Grid style={{
                        display: "flex",
                        alignContent: 'center',
                        alignItems: 'flex-end',
                        }} item xs={12} md={8}>
                        <Typography variant={'h4'}>{label}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{p: 2}}>
                <LinearProgress color={color} variant="determinate" value={value}/>
            </Box>
        </Box>
    </>
}

export interface YearPercentTabContentProps {
    preFlightPercentValue: number;
    anteFlightPercentValue: number;
    welcomeFlightPercentValue: number;
    serviceFlightPercentValue: number;
}

export const YearPercentTabContent = (props: YearPercentTabContentProps) => {
    return <>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12} md={6}>
                <PercentDimension label={'Pré-vol'} value={props.preFlightPercentValue} color={'success'}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <PercentDimension label={'Avant-vol'} value={props.anteFlightPercentValue} color={'warning'}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <PercentDimension label={'Accueil'} value={props.welcomeFlightPercentValue} color={'error'}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <PercentDimension label={'Service'} value={props.serviceFlightPercentValue} color={'secondary'}/>
            </Grid>
        </Grid>

    </>
}
