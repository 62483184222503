import {Middleware} from "@reduxjs/toolkit";
import {notesActions} from "../features/notes/notesSlice";

const notesMiddleware: Middleware = store => next => action => {
    if (action.type === 'notes/loadStoredPreferences/fulfilled') {
        store.dispatch(notesActions.onLoadPreferences(action.payload));
    }

    return next(action);
}
export default notesMiddleware;
