import {TrainingNoteDto} from "../../../../features/notes/model/notes.dto";
import {Grid} from "@mui/material";
import {formatDateWithSlashes} from "../../../../utils";

export interface TrainingNoteProps {
    details: TrainingNoteDto
}

export const TrainingNote = (props: TrainingNoteProps) => {
    return <div className={'post-it red'}>
        <Grid container>
            <Grid item xs={12} md={12}>
                <div className={"centered"}><h3>Formation</h3></div>
            </Grid>
            <Grid item xs={12} md={12}>
                <div className={"centered"}>
                {props.details.description}
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
                <div className={"centered"}>
                    {formatDateWithSlashes(new Date(props.details.date).toISOString())}
                </div>
            </Grid>
        </Grid>
    </div>
}
