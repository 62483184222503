import {Middleware} from "@reduxjs/toolkit";
import {authActions} from "../features/authentication/authSlice";

const authMiddleware: Middleware = store => next => action => {
    if (action.type === 'auth/loadStoredPreferences/fulfilled') {
        console.debug('==> on load preferences fullfilled: ', action.payload)
        store.dispatch(authActions.onLoadPreferences(action.payload));
    }

    return next(action);
}
export default authMiddleware;
