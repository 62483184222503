import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart} from "recharts"
import {CategoryEvaluation, FlightEvaluation} from "../../../features/evaluations/model";
import {useEffect, useState} from "react";
import {Card, Container} from "@mui/material";

const COMPLIANT_POINTS: number = 20;

interface RadarChartDataItem {
    category: string;
    score: number;
}

export interface SurveyItem {
    id: string;
    category: string;
    question: string;
}

const AVANT_VOL: string = "Briefing - AVANT VOL";
const PRE_VOL: string = "Préparation de l'appareil - PREVOL";
const ACCUEIL: string = "Embarquement des passagers - ACCUEIL";
const ON_BOARD: string = "En vol - SERVICE À BORD";

export const SURVEY_ITEMS: SurveyItem[] = [
    {id: "0", category: "Briefing - AVANT VOL", question: "Ponctualité"},
    {id: "1", category: "Briefing - AVANT VOL", question: "Uniforme hygiène et posture"},
    {id: "2", category: "Briefing - AVANT VOL", question: "Attention et préparation"},
    {id: "3", category: "Préparation de l'appareil - PREVOL", question: "Vérification du chargement"},
    {id: "4", category: "Préparation de l'appareil - PREVOL", question: "Compte rendu des items manquants"},
    {id: "5", category: "Préparation de l'appareil - PREVOL", question: "Vérification du standard de la cabine"},
    {id: "6", category: "Embarquement des passagers - ACCUEIL", question: "Sourire, posture générale"},
    {id: "7", category: "Embarquement des passagers - ACCUEIL", question: "Attention relationnelle"},
    {id: "8", category: "En vol - SERVICE À BORD", question: "Service restauration"},
    {id: "9", category: "En vol - SERVICE À BORD", question: "Entraide et anticipation"},
    {id: "10", category: "En vol - SERVICE À BORD", question: "Mise en valeur"},
    {id: "11", category: "En vol - SERVICE À BORD", question: "Gestion des biens"},
    {id: "12", category: "En vol - SERVICE À BORD", question: "Disponibilité et posture snack"},
    {id: "13", category: "En vol - SERVICE À BORD", question: "Maintien propreté cabine"},
    {id: "14", category: "En vol - SERVICE À BORD", question: "Prise de congés et salutations"},
    {id: "15", category: "En vol - SERVICE À BORD", question: "Vérification et rangement des zones"},
    {id: "16", category: "En vol - SERVICE À BORD", question: "Respect de la hiérarchie"},
]

const normalizeScore = (categoryScore: number, categoryItemCounts: number, maxCategoryItemCounts: number) => {
    const dimensionScore: number = categoryScore;
    const dimensionMaxScore: number = categoryItemCounts * COMPLIANT_POINTS;
    const allDimensionsMaxScore: number = maxCategoryItemCounts * COMPLIANT_POINTS;
    return normalizeDimensionScore(dimensionScore, dimensionMaxScore, allDimensionsMaxScore)
}

const normalizeDimensionScore = (dimensionScore: number, dimensionMaxScore: number, allDimensionsMaxScore: number) => {
    return (dimensionScore / dimensionMaxScore) / allDimensionsMaxScore;
}


export interface PncRadarProps {
    flightEvaluations: FlightEvaluation[]
    pnc: string
    chartH?: number;
    chartW?: number;
}

export const PncRadar = (props: PncRadarProps) => {
    const chartW: number = props.chartW ? props.chartW : window.innerWidth > 600 ? window.innerWidth * 0.57 : window.innerWidth * 0.97;
    const chartH: number = props.chartH ? props.chartH : window.innerHeight > 800 ? window.innerHeight * 0.5 : window.innerHeight * 0.3;

    const [chartData, setChartData] = useState([])// useState<RadarChartDataItem[]>([]);
    const [chartKey, setChartKey] = useState<string>('');

    const [anteFlightTotalValue, setAnteFlightTotalValue] = useState(0);
    const [preFlightTotalValue, setPreFlightTotalValue] = useState(0);
    const [welcomeFlightTotalValue, setWelcomeFlightTotalValue] = useState(0);
    const [serviceFlightTotalValue, setServiceFlightTotalValue] = useState(0);

    useEffect(() => {
        let tempAnteFlightTotalValue: number = 0;
        let tempPreFlightTotalValue: number = 0;
        let tempWelcomeFlightTotalValue: number = 0;
        let tempServiceFlightTotalValue: number = 0;

        let anteFlightCurrentCategory: CategoryEvaluation;
        let preFlightCurrentCategory: CategoryEvaluation;
        let welcomeFlightCurrentCategory: CategoryEvaluation;
        let serviceFlightCurrentCategory: CategoryEvaluation;

        props.flightEvaluations.forEach(fe => {
            if (fe.categoryEvaluations.length === 4) {
                anteFlightCurrentCategory = fe.categoryEvaluations.find(ce => ce.category === AVANT_VOL);
                tempAnteFlightTotalValue += anteFlightCurrentCategory.score;
                preFlightCurrentCategory = fe.categoryEvaluations.find(ce => ce.category === PRE_VOL);
                tempPreFlightTotalValue += preFlightCurrentCategory.score;
                welcomeFlightCurrentCategory = fe.categoryEvaluations.find(ce => ce.category === ACCUEIL);
                tempWelcomeFlightTotalValue += welcomeFlightCurrentCategory.score;
                serviceFlightCurrentCategory = fe.categoryEvaluations.find(ce => ce.category === ON_BOARD);
                tempServiceFlightTotalValue += serviceFlightCurrentCategory.score;
            }
        })
        setAnteFlightTotalValue(tempAnteFlightTotalValue);
        setPreFlightTotalValue(tempPreFlightTotalValue);
        setWelcomeFlightTotalValue(tempWelcomeFlightTotalValue);
        setServiceFlightTotalValue(tempServiceFlightTotalValue);

        setChartKey(props.pnc)

    }, [props.flightEvaluations])

    useEffect(() => {

        const data: RadarChartDataItem[] = [];

        const categories: string[] = [...new Set(SURVEY_ITEMS.map(si => si.category))].reverse();

        let categoryItemIds: string[];
        let category: string = '';
        const categoryItemCount: Map<string, number> = new Map<string, number>();

        for (let c in categories) {
            category = categories[c];
            categoryItemIds = SURVEY_ITEMS.filter(si => si.category === category).map(si => si.id);
            categoryItemCount.set(category, categoryItemIds.length);
        }

        const maxCategoryItemCounts = Math.max(...categoryItemCount.values());

        let categoryEvaluationTotalValue: number = 0;
        categories.forEach(category => {
            if (category === AVANT_VOL) {
                categoryEvaluationTotalValue = anteFlightTotalValue;
            } else if (category === PRE_VOL) {
                categoryEvaluationTotalValue = preFlightTotalValue;
            } else if (category === ACCUEIL) {
                categoryEvaluationTotalValue = welcomeFlightTotalValue;
            } else if (category === ON_BOARD) {
                categoryEvaluationTotalValue = serviceFlightTotalValue;
            }

            categoryItemIds = SURVEY_ITEMS.filter(si => si.category === category).map(si => si.id);

            data.push({
                category: category.split(' - ')[1],
                score: normalizeScore(categoryEvaluationTotalValue, categoryItemIds.length, maxCategoryItemCounts)
            })
        })


        // @ts-ignore
        setChartData(data);

    }, [anteFlightTotalValue]);

    return <div>
        <Container maxWidth="lg">
            <Card>
                <RadarChart key={chartKey}
                            style={{marginLeft: 10}}
                            height={chartH} width={chartW}
                            outerRadius="80%" data={chartData}>
                    <PolarGrid gridType={'circle'}/>
                    <PolarAngleAxis dataKey="category"/>
                    <PolarRadiusAxis tick={false}/>
                    <Radar dataKey="score" stroke="#072f60"
                           fill="green" fillOpacity={0.5}/>
                </RadarChart>
            </Card>
        </Container>
    </div>
    /*
        return <div className={'graph'}>
            {chartData.length > 0 ?
                <RadarChart key={chartKey}
                            style={{marginLeft: 10}}
                            height={chartH} width={chartW}
                            outerRadius="80%" data={chartData}>
                    <PolarGrid gridType={'circle'}/>
                    <PolarAngleAxis dataKey="category"/>
                    <PolarRadiusAxis tick={false}/>
                    <Radar dataKey="score" stroke="#072f60"
                           fill="green" fillOpacity={0.5}/>
                </RadarChart>
                : <div/>}
        </div>
    */
}
