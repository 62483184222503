import {PersonEvaluations} from "../../../features/persons/model";
import {useEffect, useState} from "react";
import {buildIpncGraphData, IpncMinMaxAverageData} from "./IpncPncsGraph";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {CartesianGrid, Legend, Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis} from "recharts";
import {Card} from "@mui/material";
import {getFilteredEvaluations} from "../../../services/dashboard.service";
import {evaluationsState} from "../../../features/evaluations/evaluationsSlice";
import {useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";

interface IpncsGraphProps {
    evaluations: PersonEvaluations[]
}

interface IpncStats {
    ipncTrigram: string;
    stats: IpncMinMaxAverageData[];
}

interface IpncDateAverageValue {
    date: string;
    ipncTrigram: string;
    averageValue: number;
}

interface AllIpncMinMaxAverageData {
    date: string;
    averageValue: number;
    ipncValues: IpncDateAverageValue[];
}

const toHtmlColour = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

export const IpncsGraph = (props: IpncsGraphProps) => {
    const chartW: number = window.innerWidth > 600 ? window.innerWidth * 0.65 : window.innerWidth * 0.93
    const chartH: number = window.innerHeight > 800 ? window.innerHeight * 0.65 : window.innerHeight * 0.3

    const [ipncs, setIpncs] = useState<string[]>([]);
    const [chartData, setChartData] = useState<any[]>([]);
    const [chartKey, setChartKey] = useState<string>('');
    const evaluations: evaluationsState = useAppSelector((state: RootState) => state.evaluations);

    useEffect(() => {
        const ipncTrigrams: string[] = [];

        let ipncTrigram: string = '';
        props.evaluations.forEach(pe => {
            ipncTrigram = pe.person.referent;
            if ('' !== ipncTrigram && !ipncTrigrams.includes(ipncTrigram)) {
                ipncTrigrams.push(ipncTrigram)
            }
        });

        setIpncs(ipncTrigrams);
        let ipncEvaluations: FlightEvaluation[] = []

        const stats: IpncStats[] = [];



        ipncTrigrams.forEach(ipnc => {

            getFilteredEvaluations(props.evaluations.filter(e => e.person.referent === ipnc), {
                fromDate: evaluations.fromDate,
                toDate: evaluations.toDate
            })
                .forEach(pe => ipncEvaluations.push(pe));

            stats.push({
                ipncTrigram: ipnc,
                stats: buildIpncGraphData(ipncEvaluations, ipnc)
            })
        })


        const allDates: string[] = [];
        stats.forEach(s => allDates.push(...s.stats.map(ss => ss.date)))
        const uniqueDates: string[] = allDates.filter(function (item, pos) {
            return allDates.indexOf(item) == pos;
        })

        const data: AllIpncMinMaxAverageData[] = [];

        let currentDateIpncCount: number = 0;
        let currentDateTotalScore: number = 0;
        let currentDateAverageScore: number = 0;
        let currentDateHighScore: number = 0;
        let currentDateLowestScore: number = 10000000000;
        let currentDateIpncDateAverageValues: IpncDateAverageValue[] = [];
        let workingStat: IpncStats;
        let workingAvgStat: IpncMinMaxAverageData;

        let currentStat: IpncMinMaxAverageData;

        uniqueDates.forEach(date => {
            currentDateIpncCount = 0;
            currentDateTotalScore = 0;
            currentDateHighScore = 0;
            currentDateAverageScore = 0;
            currentDateLowestScore = 10000000000;
            currentDateIpncDateAverageValues = [];

            stats.forEach(stat => {
                currentStat = stat.stats.find(s => s.date === date);
                if (currentStat) {
                    currentDateIpncCount++;
                    if (currentStat.averageValue > currentDateHighScore) {
                        currentDateHighScore = currentStat.averageValue;
                    }
                    if (currentStat.averageValue < currentDateLowestScore) {
                        currentDateLowestScore = currentStat.averageValue
                    }
                    currentDateTotalScore += currentStat.averageValue
                }
            });
            currentDateAverageScore = currentDateTotalScore / currentDateIpncCount;

            ipncTrigrams.forEach(ipnc => {
                workingStat = stats.find(s => s.ipncTrigram === ipnc);
                workingAvgStat = workingStat.stats.find(s => s.date === date);
                if (workingAvgStat) {
                    currentDateIpncDateAverageValues.push({
                        ipncTrigram: ipnc,
                        date: date,
                        averageValue: workingAvgStat.averageValue
                    })
                }
            });
            data.push({
                date: date,
                averageValue: currentDateAverageScore,
                ipncValues: currentDateIpncDateAverageValues
            })
        });

        const cData: any[] = [];

        let currentDateData = Object.assign({});
        data.forEach(d => {
            currentDateData = Object.assign({});
            currentDateData = Object.assign(currentDateData, {}, {date: d.date, average: d.averageValue})
            d.ipncValues.forEach(iav => {
                currentDateData = Object.assign(currentDateData, {}, JSON.parse('{"' + iav.ipncTrigram + '":"' + iav.averageValue + '"}'))
            })
            cData.push(currentDateData);
        })

        setChartData(cData);
        setChartKey(cData.length.toString());

    }, [props]);

    return <div>
        <Card>
            <div className={'graph'}>
                <LineChart
                    key={chartKey}
                    width={chartW} height={chartH}
                    margin={{
                        top: 45,
                        right: 40,
                        bottom: 5,
                    }}
                    data={chartData}>
                    <CartesianGrid strokeDasharray="1 11"/>
                    <XAxis dataKey="date" textAnchor="middle"/>
                    <ReferenceLine y={880} label="" stroke="grey" strokeDasharray="3 3" />
                    <YAxis domain={[0, 900]}/>
                    <Tooltip/>
                    <Line name={"Moyenne"} type="monotone" dataKey="average" stroke={toHtmlColour("ooo")}
                          strokeDasharray="3 4 5 2"
                    />
                    {
                        ipncs.map((ipnc, index) => {
                            return <Line key={index} name={ipnc} type="monotone" dataKey={ipnc}
                                         stroke={toHtmlColour(index.toString() + '/' + ipnc + index.toString() + '.')}/>
                        })
                    }
                    <Legend/>
                </LineChart>
            </div>
        </Card>
    </div>;
}
