import {Helmet} from "react-helmet-async";
import {Container, Grid} from "@mui/material";
import Footer from "../../../components/Footer";
import {useParams} from "react-router";
import {TotalPointsBox} from "./TotalPointsBox";
import {FlightsCountBox} from "./FlightsCountBox";
import {RewardsCollectionBox} from "./RewardsCollectionBox";
import PageHeader from "./PageHeader";
import PageTitleWrapper from "../../../components/PageTitleWrapper";
import {YearStatsTabsBox} from "./YearStatsTabsBox";
import {useAppSelector} from "../../../app/hooks";
import {RootState} from "../../../app/store";
import {useEffect, useState} from "react";
import {FlightEvaluation} from "../../../features/evaluations/model";
import {evaluationsState} from "../../../features/evaluations/evaluationsSlice";
import {PncRadar} from "./PncRadar";
import {PncGraph} from "./PncGraph";
import {getFilteredEvaluations} from "../../../services/dashboard.service";
import {PncNotes} from "./PncNotes";
import {
    CallNoteDto,
    FlightControlNoteDto,
    ObservationNoteDto,
    TrainingNoteDto
} from "../../../features/notes/model/notes.dto";
import {notesState} from "../../../features/notes/notesSlice";
import {authState} from "../../../features/authentication/authSlice";
import {Role} from "../../../features/persons/model";

function PncStats() {
    const {trigram} = useParams();
    const evaluations: evaluationsState = useAppSelector((state: RootState) => state.evaluations);
    const auth: authState = useAppSelector((state: RootState) => state.auth);
    const notes: notesState = useAppSelector((state: RootState) => state.notes);
    const [pncEvaluations, setPncEvaluations] = useState<FlightEvaluation[]>([]);
    const [pncCallNotes, setPncCallNotes] = useState<CallNoteDto[]>([]);
    const [pncTrainingNotes, setPncTrainingNotes] = useState<TrainingNoteDto[]>([]);
    const [pncObservationNotes, setPncObservationNotes] = useState<ObservationNoteDto[]>([]);
    const [pncFlightControlNotes, setPncFlightControlNotes] = useState<FlightControlNoteDto[]>([]);

    useEffect(() => {
        console.debug('evaluations /trigram / notes a ptet bougé : ', evaluations)
        setPncEvaluations(getFilteredEvaluations(evaluations.personEvaluations, {
                trigram: trigram,
                toDate: evaluations.toDate,
                fromDate: evaluations.fromDate
            }
        ));

        setPncCallNotes(notes.notes.callNotes.filter(n => n.target === trigram));
        setPncTrainingNotes(notes.notes.trainingNotes.filter(n => n.target === trigram));
        setPncObservationNotes(notes.notes.observationNotes.filter(n => n.target === trigram));
        setPncFlightControlNotes(notes.notes.flightControlNotes.filter(n => n.target === trigram));

    }, [trigram, notes, evaluations])

    const isAdmin = () => {
        return auth.roles.includes(Role.Admin) && !auth.roles.includes(Role.IPNC);
    }

    return (
        <>
            <Helmet>
                <title>CrewsBest Dashboard</title>
            </Helmet>
            <PageTitleWrapper>
                <PageHeader trigram={trigram}/>
            </PageTitleWrapper>

            <Container sx={{mt: 3}} maxWidth="lg">
                <Grid container>
                    <Grid item xs={10} md={10}>
                        <PncGraph chartH={465} chartW={1000} evaluations={pncEvaluations}
                                  trainingNotes={pncTrainingNotes}
                                  callNotes={pncCallNotes}
                                  flightControlNotes={pncFlightControlNotes}
                        />
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <div style={{paddingBottom: 10, paddingLeft: 10}}><TotalPointsBox
                            flightEvaluations={pncEvaluations}/></div>
                        <div style={{paddingBottom: 10, paddingLeft: 10}}><FlightsCountBox
                            flightEvaluations={pncEvaluations}/></div>
                        <div style={{paddingBottom: 10, paddingLeft: 10, marginBottom: 20}}><RewardsCollectionBox
                            flightEvaluations={pncEvaluations}/></div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <YearStatsTabsBox flightEvaluations={pncEvaluations}/>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <PncRadar chartH={460} chartW={560} pnc={trigram} flightEvaluations={pncEvaluations}/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <PncNotes
                            pncTrigram={trigram}
                            callNotes={pncCallNotes}
                            trainingNotes={pncTrainingNotes}
                            observationNotes={pncObservationNotes}
                            flightControlNotes={pncFlightControlNotes}
                            readonly={isAdmin()}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Footer/>
        </>
    );
}

/*
return (
    <>
      <Box display="flex" mb={3}>
        <Tooltip arrow placement="top" title="Go back">
          <IconButton color="primary" sx={{ p: 2, mr: 2 }}>
            <ArrowBackTwoToneIcon />
          </IconButton>
        </Tooltip>
        <Box>
          <Typography variant="h3" component="h3" gutterBottom>
            Profile for {user.name}
          </Typography>
          <Typography variant="subtitle2">
            This is a profile page. Easy to modify, always blazing fast
          </Typography>
        </Box>
      </Box>
      <CardCover>
        <CardMedia image={user.coverImg} />
        <CardCoverAction>
          <Input accept="image/*" id="change-cover" multiple type="file" />
          <label htmlFor="change-cover">
            <Button
              startIcon={<UploadTwoToneIcon />}
              variant="contained"
              component="span"
            >
              Change cover
            </Button>
          </label>
        </CardCoverAction>
      </CardCover>
      <AvatarWrapper>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <ButtonUploadWrapper>
          <Input
            accept="image/*"
            id="icon-button-file"
            name="icon-button-file"
            type="file"
          />
          <label htmlFor="icon-button-file">
            <IconButton component="span" color="primary">
              <UploadTwoToneIcon />
            </IconButton>
          </label>
        </ButtonUploadWrapper>
      </AvatarWrapper>
      <Box py={2} pl={2} mb={3}>
        <Typography gutterBottom variant="h4">
          {user.name}
        </Typography>
        <Typography variant="subtitle2">{user.description}</Typography>
        <Typography sx={{ py: 2 }} variant="subtitle2" color="text.primary">
          {user.jobtitle} | {user.location} | {user.followers} followers
        </Typography>
        <Box
          display={{ xs: 'block', md: 'flex' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Button size="small" variant="contained">
              Follow
            </Button>
            <Button size="small" sx={{ mx: 1 }} variant="outlined">
              View website
            </Button>
            <IconButton color="primary" sx={{ p: 0.5 }}>
              <MoreHorizTwoToneIcon />
            </IconButton>
          </Box>
          <Button
            sx={{ mt: { xs: 2, md: 0 } }}
            size="small"
            variant="text"
            endIcon={<ArrowForwardTwoToneIcon />}
          >
            See all {user.followers} connections
          </Button>
        </Box>
      </Box>
    </>
  );
* */

export default PncStats;
