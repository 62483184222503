import {Card, Grid} from "@mui/material";

import './PncNotes.css';
import {
    CallNoteDto,
    FlightControlNoteDto,
    ObservationNoteDto,
    TrainingNoteDto
} from "../../../features/notes/model/notes.dto";
import {CallNote} from "./notes/CallNote";
import {ObservationNote} from "./notes/ObservationNote";
import {TrainingNote} from "./notes/TrainingNote";
import {NewNote} from "./notes/NewNote";
import {NoteType} from "./notes/NewNoteDialog";
import {FlightControlNote} from "./notes/FlightControlNote";

export interface PncNotesProps {
    pncTrigram: string;
    callNotes: CallNoteDto[];
    trainingNotes: TrainingNoteDto[];
    observationNotes: ObservationNoteDto[];
    flightControlNotes: FlightControlNoteDto[];
    readonly?: boolean;
}

export interface INote {
    date: Date;
    type: NoteType;
    note: any;
}

export const PncNotes = (props: PncNotesProps) => {

    const prepareDisplayableNotes = (): JSX.Element[] => {
        const elements: JSX.Element[] = [];

        const allNotes: INote[] = [];

        props.callNotes.forEach(cn => {
            allNotes.push({
                type: 'call',
                date: cn.date,
                note: cn
            });
        })

        props.trainingNotes.forEach(tn => {
            allNotes.push({
                type: 'training',
                date: tn.date,
                note: tn
            });
        })

        props.observationNotes.forEach(on => {
            allNotes.push({
                type: 'observation',
                date: on.date,
                note: on
            });
        })

        props.flightControlNotes.forEach(on => {
            allNotes.push({
                type: 'flight-control',
                date: on.date,
                note: on
            });
        })
        const sortedNotes: INote[] = allNotes
            .sort(function (a, b) {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
            });

        sortedNotes.forEach(n => {
            if (n.type === 'call') {
                elements.push(<CallNote details={n.note}/>);
            } else if (n.type === 'training') {
                elements.push(<TrainingNote details={n.note}/>);
            } else if (n.type === 'flight-control') {
                elements.push(<FlightControlNote details={n.note}/>);
            } else {
                elements.push(<ObservationNote details={n.note}/>);
            }
        })

        if (!props.readonly) {
            elements.push(<NewNote targetTrigram={props.pncTrigram}/>);
        }

        return elements;
    }

    return <div>
        <Card className={"notes"}>
            <div className={'centered'}><h3>Notes</h3></div>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <div className={'inline'}>
                        {
                            prepareDisplayableNotes().map((e, k) => {
                                return <div key={k}>{e}</div>
                            })
                        }
                    </div>
                </Grid>
            </Grid>

        </Card>
    </div>
}
